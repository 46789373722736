export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajero"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadística"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesas"])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuesta"])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36"])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00"])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18"])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Negro)"])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Rojo)"])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36"])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Negro)"])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Rojo)"])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negro"])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna 1"])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna 2"])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna 3"])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuadro"])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea"])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impar"])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_0"])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_00"])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_1"])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_10"])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_11"])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_12"])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_13"])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_14"])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_15"])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_16"])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_17"])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_18"])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_19"])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_2"])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_20"])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_21"])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_22"])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_23"])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_24"])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_25"])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_26"])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_27"])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_28"])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_29"])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_3"])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_30"])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_31"])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_32"])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_33"])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_34"])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_35"])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_36"])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_4"])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_5"])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_6"])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_7"])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_8"])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUGADOR_9"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rojo"])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea(6)"])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negro"])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columna1"])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columna2"])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["columna3"])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corner"])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docena1"])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docena2"])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docena3"])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARES"])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mitad1"])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Negro)"])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Rojo)"])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mitad2"])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Negro)"])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Rojo)"])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CABEZAS"])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno1"])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno10"])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno2"])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno3"])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno4"])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno5"])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno6"])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno7"])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno8"])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno9"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linea"])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impar"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rojo"])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_a"])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_b"])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_c"])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_d"])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_e"])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_f"])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_0"])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_00"])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_1"])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_10"])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_11"])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_12"])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_13"])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_14"])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_15"])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_16"])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_17"])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_18"])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_19"])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_2"])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_20"])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_21"])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_22"])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_23"])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_24"])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_25"])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_26"])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_27"])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_28"])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_29"])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_3"])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_30"])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_31"])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_32"])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_33"])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_34"])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_35"])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_36"])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_4"])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_5"])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_6"])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_7"])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_8"])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_9"])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_a"])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_b"])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_c"])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_d"])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_e"])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_f"])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea(6)"])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["split"])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLAS"])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagada"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptada"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perdida"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación pendiente"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganada"])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apuestas"])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO MÁS APUESTAS"])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la apuesta"])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto total"])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de la apuesta"])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular recibo"])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todo"])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suma de la apuesta no puede incluir más de 2 decimales"])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cancelar el recibo: "])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo generar el recibo: "])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo pagar el recibo: "])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede recargar la caja:"])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede retirar caja:"])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego no esta asociado"])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondos insuficientes"])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derechos insuficientes"])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de seguridad no es válido"])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya empezaron uno o más sorteos"])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo ya pagado"])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de recibos rechazados alcanzado"])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El valor debe estar por encima de 0"])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max:"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min:"])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pagado"])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No jugado"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar (imprimir)"])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir (copia)"])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAR"])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MÁS"])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENOS"])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUERA DE 2"])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FUERA DE 3"])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO GANADOR"])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPAR"])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRE 2"])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GANADORES"])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRE 3"])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTA"])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUINELLA"])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIFECTA"])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GANADOR"])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GANADOR"])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdido"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pagado"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No jugado"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación pendiente"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de apuesta"])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago"])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir recibo"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo #"])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de seguridad:"])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma dividida"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago total:"])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para tabla"])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previa"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibos por página"])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deshabilitado!!!"])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige lengua:"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuesta"])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la apuesta"])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto de la apuesta"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptada"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perdida"])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagada"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación pendiente"])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelada"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganada"])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular recibo"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apostado"])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego"])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago"])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajero"])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de apuesta"])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptado"])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de cancelación"])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detallado"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar (imprimir)"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir (copia)"])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir reporte"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdido"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pagado"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No jugado"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación pendiente"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de seguridad:"])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apostado"])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago pendiente"])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibos aceptados"])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibos cancelados"])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total depositado (cajero)"])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total depositado (billetero)"])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pagado"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas del juego"])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas de la tabla"])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago total:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botones"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear"])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear (stat)"])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de recargas/retiros puesto"])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos no encontrados"])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno"])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todo"])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesa"])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total recargado"])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total retirado"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrado"])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga [Billetero]"])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga [Cajero]"])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro [Cajero]"])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado por el cajero"])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado_error"])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pago_bloqueado"])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (pin"])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (estadística)"])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (pendiente de acceso)"])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apostando"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo"])}
    }
  }
}