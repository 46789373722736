<template>
  <div class="langPosition">
    <button v-for="(lang, idx) in langs" :key="idx"
            href="#"
            class="flex items-center langDropdownButton"
            :class="{'lang_selected': lang.shortName === $i18n.locale}"
            @click="setLocale(lang.shortName)"
            @keydown.space.exact.prevent="toggleVisibility"
            @keydown.esc.exact="hideDropdown"
            @keydown.shift.tab="hideDropdown"
            @keydown.up.exact.prevent="startArrowKeys"
            @keydown.down.exact.prevent="startArrowKeys"
    >
      <img :src="lang.flag" :alt="lang.longName" class="flag" :title="lang.localName"/>
    </button>
  </div>
</template>

<script setup>
import {inject, ref} from "vue";

const $i18n = inject('$i18n')
const isVisible = ref(false)
const focusedIndex = ref(0)
const langs = ref([
  {
    shortName: "en",
    longName: "English",
    localName: "English",
    flag: "LanguageSwitcher/en.svg"
  },
  {
    shortName: "es",
    longName: "Spanish",
    localName: "Español",
    flag: "LanguageSwitcher/es.svg"
  },
  {
    shortName: "fr",
    longName: "French",
    localName: "Français",
    flag: "LanguageSwitcher/fr.svg"
  },
  {
    shortName: "br",
    longName: "Brasil",
    localName: "Brasil",
    flag: "LanguageSwitcher/br.svg"
  },
  {
    shortName: "ru",
    longName: "Russian",
    localName: "Русский",
    flag: "LanguageSwitcher/ru.svg"
  }
])

function toggleVisibility()
{
  isVisible.value = !isVisible.value;
}

function hideDropdown()
{
  isVisible.value = false;
  focusedIndex.value = 0;
}

function startArrowKeys()
{
  if (isVisible.value) {
    this.$refs.dropdown.children[0].children[0].focus();
  }
}

function setLocale(locale)
{
  $i18n.locale = locale;
  localStorage.setItem("lang", locale);
  hideDropdown();
}
</script>

<style scoped>
.langPosition {
  display: flex;
  justify-content: center;
  top: 1%;
  right: 1%;
}

.langDropdownButton {
  outline: none;
  cursor: pointer !important;
  background-color: transparent !important;
  border: none;
  padding-right: 0px;
}

ul {
  text-align: left;
  min-width: 170px;
  border-radius: 8px;
  background: white;
  z-index: 500;
  position: absolute;
  list-style: none;
  padding: 10px 10px !important;
  margin: 0px !important;
  top: 1%;
  right: 1%;
}

.flag {
  width: 30px;
}

.langDropdownButton img {
  user-select: none;
  box-shadow: 0 0 5px 2px gray;
  width: 28px;
}

.lang_selected img
{
  box-shadow: 0 0 5px 2px blue;
}

</style>
