<template>
  <div></div>
</template>

<script>
import cashier from "@/services/cashier.service";
import {inject, watch, onMounted, nextTick} from "vue";

export default {
  name: "Preload",
  setup()
  {
    const setIsUpBalance = inject('setIsUpBalance');

    onMounted(() => {
      getGames();
      getBalance();
      getBetsButtons();
      getTables();
      clearInterval(timer.value);
      timer.value = undefined;
      if(timer.value === undefined)
      {
        timer.value = setInterval(updateInstance, 30000);
      }
    })

    const timer = inject('timer');
    const setSelectedGame = inject('setSelectedGame');
    const setGame = inject('setGame');
    const setBalance = inject('setBalance');
    const set_result = inject('set_result');
    const gameId = inject('gameId');
    const bet_buttons = inject('bet_buttons');
    const setCurrencySymbol = inject('setCurrencySymbol');
    const bet_value = inject('bet_value');
    const checkGamesScroll = inject('checkGamesScroll');
    const verifyVer = inject('verifyVer');
    const setTables = inject('setTables')

    watch(gameId, (first, second) => {
      if(first === -1 || first === -2)
      {
        getGames()
      }
      if(first === -2)
      {
          getTables()
      }
    });

    const getGames = () =>
    {
      cashier.getGames().then(
          (response) => {
            verifyVer(response.headers.ver);
            if(response.data)
            {
              setGame(response.data);
              let is_game_binded = false;

              for(let game of response.data)
              {
                if(gameId.value === game.id)
                {
                  is_game_binded = true;
                }
              }

              if(!is_game_binded || gameId.value === 0)
              {
                setSelectedGame(response.data[0].game_type, response.data[0].id, response.data[0].name, response.data[0].game_kind, response.data[0].bet_alias_code, response.data[0].extra_bet_type);
              }
            }
            else
            {
              setGame('');
              setSelectedGame('', -1, '', '');
            }

            nextTick(function () {
              checkGamesScroll()
            })
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const getBalance = () =>
    {
      cashier.getBalance().then(
          (response) => {
            setBalance(response.data);
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const getTables = () =>
    {
      cashier.getTables().then(
          (response) => {
            setTables(response.data);
          },
          (error) => {
            set_result(error.response.status)
          }
      );
    }

    const route = inject('route');
    const isAuthorized = inject('isAuthorized');
    const getBetsButtons = () =>
    {
      cashier.getBetsButtons().then(
          (response) => {
            bet_buttons.value = response.data;

            if(bet_value.value === '')
            {
              bet_value.value = bet_buttons.value.b1;
            }

            setCurrencySymbol(bet_buttons.value.symbol, bet_buttons.value.iso);
          },
          (error) => {
            this.set_result(error.response.status)
          }
      );
    }

    const updateInstance = () =>
    {
      if(!isAuthorized.value)
      {
        return;
      }

      getBalance();
      getGames();
      if(route.name !== 'statistics')
      {
        getBetsButtons();
      }
      if(route.name === 'tables')
      {
        getTables();
      }
      setIsUpBalance();
    }

    return {
      getGames,
      setSelectedGame,
      setGame,
      setBalance,
      getBalance,
      set_result,
      route,
      getBetsButtons,
      timer,
      updateInstance,
      checkGamesScroll,
      verifyVer,
      getTables
    }
  },
  watch: {
    isUpBalance: function() {
      this.getBalance();
    }
  }
}
</script>

<style scoped>

</style>