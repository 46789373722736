export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashier"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LogOut"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tables"])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply bet"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet"])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36"])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00"])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18"])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Black)"])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Red)"])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36"])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Black)"])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Red)"])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black"])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column 1"])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column 2"])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column 3"])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corner"])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even"])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line"])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odd"])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_0"])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_00"])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_1"])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_10"])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_11"])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_12"])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_13"])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_14"])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_15"])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_16"])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_17"])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_18"])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_19"])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_2"])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_20"])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_21"])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_22"])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_23"])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_24"])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_25"])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_26"])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_27"])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_28"])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_29"])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_3"])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_30"])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_31"])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_32"])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_33"])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_34"])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_35"])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_36"])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_4"])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_5"])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_6"])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_7"])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_8"])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER_9"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Six-line"])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["black"])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["column1"])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["column2"])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["column3"])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corner"])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doz1"])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doz2"])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doz3"])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["even"])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVENS"])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half1"])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Black)"])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Red)"])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half2"])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Black)"])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Red)"])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEADS"])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno1"])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno10"])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno2"])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno3"])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno4"])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno5"])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno6"])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno7"])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno8"])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno9"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["line"])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odd"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["red"])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_a"])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_b"])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_c"])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_d"])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_e"])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_f"])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_0"])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_00"])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_1"])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_10"])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_11"])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_12"])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_13"])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_14"])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_15"])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_16"])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_17"])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_18"])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_19"])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_2"])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_20"])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_21"])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_22"])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_23"])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_24"])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_25"])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_26"])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_27"])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_28"])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_29"])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_3"])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_30"])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_31"])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_32"])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_33"])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_34"])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_35"])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_36"])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_4"])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_5"])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_6"])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_7"])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_8"])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMBER_9"])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_a"])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_b"])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_c"])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_d"])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_e"])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector_f"])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["six-line"])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["split"])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAILS"])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lost"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending_confirmation"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["win"])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bets"])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO MORE BETS"])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet status"])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bets value"])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet value"])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel receipt"])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet sum cannot includes more than 2 decimals"])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot cancel receipt: "])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot make receipt: "])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot payout receipt: "])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot refill box:"])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot withdraw box:"])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game untied"])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient funds"])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient privileges"])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid secure code"])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more events were already started"])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt already paid"])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt rejects limit reached"])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value must be above 0"])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max:"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min:"])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not played"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout (print)"])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print copy"])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVEN"])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIGH"])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOW"])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO PLACE 1-2"])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO SHOW 1-3"])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO WINNER"])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODD"])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLACE 1-2"])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WINNERS"])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW 1-3"])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTA"])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUINELLA"])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIFECTA"])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WINNER"])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WINNER"])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lost"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not_paid"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not_played"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending_confirmation"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["playing"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejected"])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt date"])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout date"])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat receipt"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round #"])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure code:"])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split sum"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payout:"])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data for table"])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive!!!"])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose language:"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet"])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet status"])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet value"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepted"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lost"])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending_confirmation"])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejected"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["win"])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel receipt"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered"])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game"])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout date"])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout login"])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt date"])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt login"])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject date"])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject login"])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overview"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout (print)"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print copy"])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print report"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lost"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not_paid"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not_played"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending_confirmation"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["playing"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rejected"])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure code:"])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set time"])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered"])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payout"])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts rejected"])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total deposited (cashier)"])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total deposited (bill acceptor)"])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total paid"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game statistics"])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table statistics"])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payout:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock"])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock"])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock (stat)"])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of refill/withdraw box"])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found"])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift"])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total refill"])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total withdraw"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shown"])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refill [Acceptor]"])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refill [Cashier]"])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw [Cashier]"])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_cashier"])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_error"])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_payout"])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_pin("])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_stat"])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blocked_stat_pending"])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in_game"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ready"])}
    }
  }
}