<template>
  <div id="workspace" @click="getBet">
    <div id="game_bets">
      <div class="half">
        <div class="zero bet_cell" value="0" :class="{'selected': checkIsSelected('0'), 'blocked_bet': bet_mode !== 'normal'}" multiplier="36">0</div>
        <div class="h1_column">
          <div
            v-for="bet in h1_column_1"
            :key="bet.value"
            :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value, bet.split, bet.corner, bet.line)"
            :value="bet.value"
            :multiplier="bet.multiplier">{{tBetName(bet.name)}}
          </div>
        </div>
        <div class="h1_column">
          <div
              v-for="bet in h1_column_2"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value, bet.split, bet.corner)"
              :value="bet.value"
              :multiplier="bet.multiplier">{{tBetName(bet.name)}}
          </div>
        </div>
        <div class="h1_column">
          <div
              v-for="bet in h1_column_3"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value, bet.split, bet.corner)"
              :value="bet.value"
              :multiplier="bet.multiplier">{{tBetName(bet.name)}}
          </div>
        </div>
      </div>
      <div class="half">
        <div
          v-if="game_kind === 'withtwozeros'"
          class="zzero bet_cell" value="00"
          :class="{'selected': checkIsSelected('00'), 'blocked_bet': bet_mode !== 'normal'}"
          multiplier="36"
        >00</div>
        <div v-if="game_kind !== 'withtwozeros'" class="zzero bet_cell no_bet"></div>
        <div class="h2_1_column">
          <div
              v-for="bet in h2_1_column"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
              :value="bet.value"
              :multiplier="bet.multiplier">{{bet.name}}
          </div>
          <div class="sectors">
            <div
                v-for="bet in retSector1()"
                :key="bet.value"
                :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
                :value="bet.value"
                :multiplier="bet.multiplier">{{bet.name}}
            </div>
          </div>
        </div>
        <div class="h2_2_column">
          <div
              v-for="bet in h2_2_column"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
              :value="bet.value"
              :multiplier="bet.multiplier">{{tBetName(bet.name)}}
          </div>
          <div class="sectors">
            <div
                v-for="bet in retSector2()"
                :key="bet.value"
                :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
                :value="bet.value"
                :multiplier="bet.multiplier">{{bet.name}}
            </div>
          </div>
        </div>

      </div>
      <div class="extras">
        <div
            v-for="bet in half_rb"
            :key="bet.value"
            :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
            :value="bet.value"
            :multiplier="bet.multiplier">{{tBetName(bet.name)}}
        </div>
      </div>
      <div class="extras">
        <div
            v-for="bet in splits"
            :key="bet.value"
            :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)+' '+checkIsBlocked(bet.type, bet.value)"
            :value="bet.value"
            :multiplier="bet.multiplier">{{tBetName(bet.name)}}
        </div>
      </div>
      <div v-if="extra_bet === 'advanced'" class="extras extras_dashed">
        <div
            v-for="bet in modes_simple"
            :key="bet.value"
            :value="bet.value"
            :class="'bet_cell '+bet.class+' '+checkIsModeOn(bet.value)"
            @click="setBetMode(bet.value, bet.bets_count, bet.bets_multi)"
        >
          {{tBetName(bet.name)}}
        </div>
      </div>
      <div v-if="extra_bet === 'classic'" class="extras extras_dashed">
        <div
            v-for="bet in modes"
            :key="bet.value"
            :value="bet.value"
            :class="'bet_cell '+bet.class+' '+checkIsModeOn(bet.value)"
            @click="setBetMode(bet.value, bet.bets_count, bet.bets_multi)"
        >
          {{tBetName(bet.name)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import GameService from '../../services/game.service';

export default {
  name: "Roulette",
  data () {
    return {
      selected: [],
      cur_split_selected: [],
      split_bets_count: 0,
      split_bets_multi: 0,
      bet_mode: 'normal',
      h1_column_1:
      [
        {name: 3, value: '3', class: 'red', multiplier: '36', type: 'primitive', split: ['2','6'], corner: ['2','5','6'], line: ['1','2']},
        {name: 6, value: '6', class: 'black', multiplier: '36', type: 'primitive', split: ['3','5','9'], corner: ['2','3','5','8','9'], line: ['4','5']},
        {name: 9, value: '9', class: 'red', multiplier: '36', type: 'primitive', split: ['6','8','12'], corner: ['5','6','8','11','12'], line: ['7','8']},
        {name: 12, value: '12', class: 'red', multiplier: '36', type: 'primitive', split: ['9','11','15'], corner: ['8','9','11','14','15'], line: ['10','11']},
        {name: 15, value: '15', class: 'black', multiplier: '36', type: 'primitive', split: ['12','14','18'], corner: ['11','12','14','17','18'], line: ['13','14']},
        {name: 18, value: '18', class: 'red', multiplier: '36', type: 'primitive', split: ['15','17','21'], corner: ['14','15','17','20','21'], line: ['16','17']},
        {name: 21, value: '21', class: 'red', multiplier: '36', type: 'primitive', split: ['18','20','24'], corner: ['17','18','20','23','24'], line: ['19','20']},
        {name: 24, value: '24', class: 'black', multiplier: '36', type: 'primitive', split: ['21','23','27'], corner: ['20','21','23','26','27'], line: ['22','23']},
        {name: 27, value: '27', class: 'red', multiplier: '36', type: 'primitive', split: ['24','26','30'], corner: ['23','24','26','29','30'], line: ['25','26']},
        {name: 30, value: '30', class: 'red', multiplier: '36', type: 'primitive', split: ['27','29','33'], corner: ['26','27','29','32','33'], line: ['28','29']},
        {name: 33, value: '33', class: 'black', multiplier: '36', type: 'primitive', split: ['30','32','36'], corner: ['29','30','32','35','36'], line: ['31','32']},
        {name: 36, value: '36', class: 'red', multiplier: '36', type: 'primitive', split: ['33','35'], corner: ['32','33','35'], line: ['34','35']},
        {name: 'Column 3', value: 'column3', class: 'bisque tc_color', multiplier: '3', type: 'group'}
      ],
      h1_column_2:
      [
        {name: 2, value: '2', class: 'black', multiplier: '36', type: 'primitive', split: ['1','3','5'], corner: ['1','3','4','5','6']},
        {name: 5, value: '5', class: 'red', multiplier: '36', type: 'primitive', split: ['2','4','6','8'], corner: ['1','2','3','4','6','7','8','9']},
        {name: 8, value: '8', class: 'black', multiplier: '36', type: 'primitive', split: ['5','7','9','11'], corner: ['4','5','6','7','9','10','11','12']},
        {name: 11, value: '11', class: 'black', multiplier: '36', type: 'primitive', split: ['8','10','12','14'], corner: ['7','8','9','10','12','13','14','15']},
        {name: 14, value: '14', class: 'red', multiplier: '36', type: 'primitive', split: ['11','13','15','17'], corner: ['10','11','12','13','15','16','17','18']},
        {name: 17, value: '17', class: 'black', multiplier: '36', type: 'primitive', split: ['14','16','18','20'], corner: ['13','14','15','16','18','19','20','21']},
        {name: 20, value: '20', class: 'black', multiplier: '36', type: 'primitive', split: ['17','19','21','23'], corner: ['16','17','18','19','21','22','23','24']},
        {name: 23, value: '23', class: 'red', multiplier: '36', type: 'primitive', split: ['20','22','24','26'], corner: ['19','20','21','22','24','25','26','27']},
        {name: 26, value: '26', class: 'black', multiplier: '36', type: 'primitive', split: ['23','25','27','29'], corner: ['22','23','24','25','27','28','29','30']},
        {name: 29, value: '29', class: 'black', multiplier: '36', type: 'primitive', split: ['26','28','30','32'], corner: ['25','26','27','28','30','31','32','33']},
        {name: 32, value: '32', class: 'red', multiplier: '36', type: 'primitive', split: ['29','31','33','35'], corner: ['28','29','30','31','33','34','35','36']},
        {name: 35, value: '35', class: 'black', multiplier: '36', type: 'primitive', split: ['32','34','36'], corner: ['31','32','33','34','36']},
        {name: 'Column 2', value: 'column2', class: 'bisque tc_color', multiplier: '3', type: 'group'}
      ],
      h1_column_3:
      [
        {name: 1, value: '1', class: 'red', multiplier: '36', type: 'primitive', split: ['2','4'], corner: ['2','4','5']},
        {name: 4, value: '4', class: 'black', multiplier: '36', type: 'primitive', split: ['1','5','7'], corner: ['1','2','5','7','8']},
        {name: 7, value: '7', class: 'red', multiplier: '36', type: 'primitive', split: ['4','8','10'], corner: ['4','5','8','10','11']},
        {name: 10, value: '10', class: 'black', multiplier: '36', type: 'primitive', split: ['7','11','13'], corner: ['7','8','11','13','14']},
        {name: 13, value: '13', class: 'black', multiplier: '36', type: 'primitive', split: ['10','14','16'], corner: ['10','11','14','16','17']},
        {name: 16, value: '16', class: 'red', multiplier: '36', type: 'primitive', split: ['13','17','19'], corner: ['13','14','17','19','20']},
        {name: 19, value: '19', class: 'red', multiplier: '36', type: 'primitive', split: ['16','20','22'], corner: ['16','17','20','22','23']},
        {name: 22, value: '22', class: 'black', multiplier: '36', type: 'primitive', split: ['19','23','25'], corner: ['19','20','23','25','26']},
        {name: 25, value: '25', class: 'red', multiplier: '36', type: 'primitive', split: ['22','26','28'], corner: ['22','23','26','28','29']},
        {name: 28, value: '28', class: 'black', multiplier: '36', type: 'primitive', split: ['25','29','31'], corner: ['25','26','29','31','32']},
        {name: 31, value: '31', class: 'black', multiplier: '36', type: 'primitive', split: ['28','32','34'], corner: ['28','29','32','34','35']},
        {name: 34, value: '34', class: 'red', multiplier: '36', type: 'primitive', split: ['31','35'], corner: ['31','32','35']},
        {name: 'Column 1', value: 'column1', class: 'bisque tc_color', multiplier: '3', type: 'group'}
      ],
      h2_1_column:
      [
        {name: '1-12', value: 'doz1', class: 'bisque tc_color', multiplier: '3', type: 'group'},
        {name: '13-24', value: 'doz2', class: 'bisque tc_color', multiplier: '3', type: 'group'},
        {name: '25-36', value: 'doz3', class: 'bisque tc_color', multiplier: '3', type: 'group'}
      ],
      h2_2_column:
      [
        {name: '1-18', value: 'half1', class: 'bisque tc_color', multiplier: '2', type: 'group'},
        {name: 'Even', value: 'even', class: 'bisque tc_color', multiplier: '2', type: 'group'},
        {name: 'Red', value: 'red', class: 'red', multiplier: '2', type: 'group'},
        {name: 'Black', value: 'black', class: 'black', multiplier: '2', type: 'group'},
        {name: 'Odd', value: 'odd', class: 'bisque tc_color', multiplier: '2', type: 'group'},
        {name: '19-36', value: 'half2', class: 'bisque tc_color', multiplier: '2', type: 'group'}
      ],
      sector_1:
      [
        {name: 'A', value: 'sector_a', class: 'bisque tc_color', multiplier: '6', type: 'group'},
        {name: 'B', value: 'sector_b', class: 'bisque tc_color', multiplier: '6', type: 'group'},
        {name: 'C', value: 'sector_c', class: 'bisque tc_color', multiplier: '6', type: 'group'}
      ],
      sector_2:
      [
        {name: 'D', value: 'sector_d', class: 'bisque tc_color', multiplier: '6', type: 'group'},
        {name: 'E', value: 'sector_e', class: 'bisque tc_color', multiplier: '6', type: 'group'},
        {name: 'F', value: 'sector_f', class: 'bisque tc_color', multiplier: '6', type: 'group'}
      ],
      sector0_1:
          [
            {name: 'A', value: 'sector0_a', class: 'bisque tc_color', multiplier: '6', type: 'group'},
            {name: 'B', value: 'sector0_b', class: 'bisque tc_color', multiplier: '6', type: 'group'},
            {name: 'C', value: 'sector0_c', class: 'bisque tc_color', multiplier: '6', type: 'group'}
          ],
      sector0_2:
          [
            {name: 'D', value: 'sector0_d', class: 'bisque tc_color', multiplier: '6', type: 'group'},
            {name: 'E', value: 'sector0_e', class: 'bisque tc_color', multiplier: '6', type: 'group'},
            {name: 'F', value: 'sector0_f', class: 'bisque tc_color', multiplier: '6', type: 'group'}
          ],
      half_rb:
      [
        {name: '1-18 (Red)', value: 'half1_red', class: 'bisque tc_color', multiplier: '4', type: 'group'},
        {name: '19-36 (Red)', value: 'half2_red', class: 'bisque tc_color', multiplier: '4', type: 'group'},
        {name: '1-18 (Black)', value: 'half1_black', class: 'bisque tc_color', multiplier: '4', type: 'group'},
        {name: '19-36 (Black)', value: 'half2_black', class: 'bisque tc_color', multiplier: '4', type: 'group'}
      ],
      splits:
      [
        {name: '12 & 21', value: '12_21', class: 'bisque tc_color', multiplier: '18', type: 'group'},
        {name: '13 & 31', value: '13_31', class: 'bisque tc_color', multiplier: '18', type: 'group'},
        {name: '23 & 32', value: '23_32', class: 'bisque tc_color', multiplier: '18', type: 'group'},
        {name: '11 & 22 & 33', value: '11_22_33', class: 'bisque tc_color', multiplier: '12', type: 'group'}
      ],
      modes:
      [
        {name: 'Split', value: 'split', class: 'bisque tc_color', type: 'mode', bets_count: 2, bets_multi: 18},
        {name: 'Corner', value: 'corner', class: 'bisque tc_color', type: 'mode', bets_count: 4, bets_multi: 9},
        {name: 'Line', value: 'line', class: 'bisque tc_color', type: 'mode', bets_count: 1, bets_multi: 12},
        {name: 'Six-line', value: 'six-line', class: 'bisque tc_color', type: 'mode', bets_count: 2, bets_multi: 6}
      ],
      modes_simple:
      [
        {name: 'Split', value: 'split', class: 'bisque tc_color', type: 'mode', bets_count: 2, bets_multi: 18},
        {name: 'Corner', value: 'corner', class: 'bisque tc_color', type: 'mode', bets_count: 4, bets_multi: 9},
        {name: 'Line', value: 'line', class: 'bisque tc_color', type: 'mode', bets_count: 3, bets_multi: 12},
        {name: 'Six-line', value: 'six-line', class: 'bisque tc_color', type: 'mode', bets_count: 6, bets_multi: 6}
      ],
      bet_aliases: []
    }
  },
  setup()
  {
    const game_kind = inject('gameKind')
    const game_alias = inject('gameAlias')
    const extra_bet = inject('gameExtra')

    return {
      game_kind,
      game_alias,
      extra_bet
    }
  },
  props: ['event', 'public_event', 'bet_value'],
  methods: {
    getBet (event) {

      if(this.event === '')
      {
        return;
      }

      if(event.target.attributes.value === undefined || event.target.attributes.multiplier === undefined)
      {
        return;
      }

      let div_val = event.target.attributes.value;
      let even = this.event;
      let bet_value = this.bet_value;
      let multiplier = event.target.attributes.multiplier.value;
      let public_event = this.public_event;

      if(div_val !== undefined)
      {
        let bet = event.target.attributes.value.value;

        if(this.bet_mode === 'normal')
        {
          let found = '';

          for (let sel of this.selected)
          {
            if (sel.bet === bet && sel.event === even)
            {
              found = sel;
            }
          }

          if (found !== '')
          {
            this.selected.splice(this.selected.indexOf(found), 1);
          }
          else
          {
            if (this.selected.length < 40 && this.bet_value > 0 && even !== '')
            {
              this.selected.push({
                bet: bet,
                event: even,
                public_event: public_event,
                bet_value: bet_value,
                multiplier: multiplier,
                bet_name: this.rename_bet(bet),
                ntt: this.bet_aliases.need_translate
              });
            }
          }
        }

        if(this.bet_mode !== 'normal')
        {
          let found = '';

          for (let sel of this.cur_split_selected)
          {
            if (sel.bet === bet)
            {
              found = sel;
            }
          }

          if (found !== '')
          {
            this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
          }
          else
          {
            this.cur_split_selected.push({bet: bet});
          }

          if(this.split_bets_count > 0 && this.cur_split_selected.length === this.split_bets_count)
          {
            let sorted = this.cur_split_selected.sort(this.sortFn);

            bet = this.bet_mode;

            if(this.extra_bet === 'advanced' || (this.extra_bet === 'classic' && (this.bet_mode === 'corner' || this.bet_mode === 'split')))
            {
              for(let step = 0; step <= this.split_bets_count-1; step++)
              {
                bet += '_'+sorted[step].bet;
              }
            }

            if(this.extra_bet === 'classic')
            {
              if(this.bet_mode === 'line' || this.bet_mode === 'six-line')
              {
                for(let step = 0; step <= this.split_bets_count-1; step++)
                {
                  bet += '_'+sorted[step].bet;
                  for(let bet_row of this.h1_column_1)
                  {
                    if(bet_row.value === sorted[step].bet)
                    {
                      for(let bet_line of bet_row.line)
                      {
                        bet += '_'+bet_line;
                      }
                    }
                  }
                }
              }
            }

            multiplier = this.split_bets_multi;

            this.selected.push({
              bet: bet,
              event: even,
              public_event: public_event,
              bet_value: bet_value,
              multiplier: multiplier,
              bet_name: this.rename_bet(bet),
              ntt: this.bet_aliases.need_translate
            });
            this.cur_split_selected = [];
            this.split_bets_count = 0;
            this.split_bets_multi = 0;
            this.bet_mode = 'normal';
          }
        }

        this.$emit('getBet', this.selected);
      }
    },
    sortFn(a, b)
    {
      let fa = parseInt(a.bet),
          fb = parseInt(b.bet);

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    checkIsSelected(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.selected)
      {
        if(sel.event === event && this.bet_mode === 'normal')
        {
          let sel_bet_arr = sel.bet.split('_');

          if(sel.bet === val)
          {
            ret += 'selected ';
          }

          if((sel_bet_arr.includes('split') || sel_bet_arr.includes('corner') || sel_bet_arr.includes('line') || sel_bet_arr.includes('six-line'))&& sel_bet_arr.includes(val))
          {
            ret += 'selected_shatters ';
          }
        }
      }

      for(let sel of this.cur_split_selected)
      {
        if(this.bet_mode !== 'normal')
        {
          if (sel.bet === val)
          {
            ret += 'selected ';
          }
        }
      }
      return ret;
    },
    checkIsBlocked(type, value, split, corner, line)
    {
      let ret = '';

      if(type !== 'primitive' && this.bet_mode !== 'normal')
      {
        ret = 'blocked_bet';
      }

      if(this.cur_split_selected.length > 0 && this.bet_mode !== 'normal' && this.extra_bet === 'classic')
      {
        if(split !== undefined && (this.bet_mode === 'split' || this.bet_mode === 'six-line'))
        {
          ret = 'blocked_bet';

          for(let spl of split)
          {
            if(this.cur_split_selected[0].bet === spl)
            {
              ret = '';
            }
          }
        }

        if(corner !== undefined && this.bet_mode === 'corner')
        {
          let cret = '';

          for(let css of this.cur_split_selected)
          {
            let found = false;

            for(let cv of corner)
            {
              if(css.bet === cv)
              {
                found = true;
              }
            }

            if(!found)
            {
              cret = 'blocked_bet';
            }
          }

          ret = cret;
        }
      }

      if(this.extra_bet === 'classic' && (this.bet_mode === 'line' || this.bet_mode === 'six-line'))
      {
        if(line === undefined)
        {
          ret = 'blocked_bet';
        }
      }

      return ret;
    },
    checkIsModeOn(mode)
    {
      if(this.bet_mode === mode)
      {
        return 'mode_selected';
      }
      if(this.bet_mode !== mode && this.bet_mode !== 'normal')
      {
        return 'blocked_bet';
      }

      return '';
    },
    clear_bet()
    {
      this.selected = [];
      this.getBetAliases();
    },
    rename_bet(bet)
    {
      let rbet = bet;

      if(!isNaN(bet))
      {
        rbet = 'sector_'+bet;
      }

      if(this.bet_aliases.aliases !== undefined && this.bet_aliases.aliases[bet] !== undefined)
      {
        rbet = this.bet_aliases.aliases[bet]+'_'+bet;
      }

      rbet = rbet.replace('sector0','sector');

      return rbet;
    },
    tBetName(name)
    {
      if(this.$t('cashier.betName.'+name) !== 'cashier.betName.'+name)
      {
        return this.$t('cashier.betName.'+name);
      }
      return name;
    },
    setBetMode(mode, bets_count, multi)
    {
      if(this.event === '')
      {
        return;
      }

      if(this.bet_mode === mode)
      {
        this.bet_mode = 'normal';
        this.cur_split_selected = [];
      }
      else
      {
        this.bet_mode = mode;
      }

      this.split_bets_count = bets_count;
      this.split_bets_multi = multi;
    },
    getBetAliases()
    {
      if(this.game_alias !== null && this.game_alias !== undefined && this.game_alias !== '')
      {
        GameService.getBetAliases(this.game_alias).then(
            (response) => {
              this.bet_aliases = response.data;
            }
        );
      }
    },
    retSector1()
    {
      if(this.game_kind === 'withtwozeros')
      {
        return this.sector_1
      }
      else
      {
        return this.sector0_1
      }
    },
    retSector2()
    {
      if(this.game_kind === 'withtwozeros')
      {
        return this.sector_2
      }
      else
      {
        return this.sector0_2
      }
    }
  },
  mounted() {
    this.getBetAliases();
  }
}
</script>

<style scoped>

  #workspace
  {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cornflowerblue;
  }
  #game_bets
  {
    height: 97%;
    width: 95%;
    display: grid;
    grid-template-rows: 38% 38% 8% 8% 8%;
  }
  .half
  {
    display: grid;
    grid-template-columns: 5% 95%;
  }
  .bet_cell
  {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DDDDDD;
    margin: 2px;
    cursor:pointer;
    font-size: 70%;
    font-weight: bold;
  }
  .zero
  {
    background-color: green;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .zzero
  {
    background-color: green;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .sectors
  {
    display: grid;
    grid-template-rows: 33.3% 33.3% 33.3%;
  }
  .h1_column
  {
    display: grid;
    grid-template-columns: 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 15%;
  }
  .h2_1_column
  {
    display: grid;
    grid-template-columns: 28% 28% 28% 15%;
  }
  .h2_2_column
  {
    display: grid;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 15%;
  }
  .extras
  {
    width: 99%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
  .extras_dashed
  {
    border: 4px dashed chartreuse;
  }
  .red
  {
    background-color: red;
  }
  .black
  {
    background-color: black;
  }
  .bisque
  {
    background-color: aliceblue;
    color: black;
  }
  .selected
  {
    box-shadow: inset 0px 0px 0px 5px #53ea93;
    box-sizing: border-box;
    background-color: darkorchid;
    color: white;
  }
  .selected_shatters
  {
    background-color: darkkhaki;
    color: white;
  }
  .no_bet
  {
    background-color: cornflowerblue;
    pointer-events: none;
  }
  .blocked_bet
  {
    background-color: dimgrey;
    pointer-events: none;
  }
  .mode_selected
  {
    background-color: #53ea93;
  }
</style>