import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faTrashAlt,
  faStar as fasStar,
  faLock,
  faExchangeAlt,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import {
  faStar as farStar
} from "@fortawesome/free-regular-svg-icons";
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faTrashAlt, fasStar, farStar, faLock, faExchangeAlt, faCaretUp, faCaretDown);

export { FontAwesomeIcon };
