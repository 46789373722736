export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo "])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encerrar sesão "])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatística"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesas"])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aposta"])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36"])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00"])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18"])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Preto)"])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Vermelho)"])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36"])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Preto)"])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Vermelho)"])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preto"])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coluna 1"])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coluna 2"])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coluna 3"])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadro"])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha"])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impar"])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_0"])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_00"])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_1"])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_10"])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_11"])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_12"])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_13"])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_14"])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_15"])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_16"])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_17"])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_18"])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_19"])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_2"])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_20"])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_21"])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_22"])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_23"])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_24"])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_25"])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_26"])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_27"])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_28"])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_29"])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_3"])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_30"])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_31"])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_32"])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_33"])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_34"])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_35"])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_36"])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_4"])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_5"])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_6"])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_7"])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_8"])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOGADOR_9"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermelho "])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha(6)"])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preto"])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna1"])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna2"])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna3"])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corner"])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dúzia1"])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dúzia2"])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dúzia3"])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMPATE"])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metade1"])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Preto)"])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Vermelho)"])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metade2"])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Preto)"])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Vermelho)"])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PRIMEIRA METADE"])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno1"])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno10"])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno2"])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno3"])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno4"])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno5"])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno6"])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno7"])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno8"])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno9"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linha"])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impar"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermelho "])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_a"])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_b"])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_c"])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_d"])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_e"])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_f"])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_0"])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_00"])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_1"])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_10"])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_11"])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_12"])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_13"])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_14"])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_15"])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_16"])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_17"])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_18"])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_19"])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_2"])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_20"])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_21"])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_22"])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_23"])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_24"])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_25"])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_26"])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_27"])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_28"])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_29"])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_3"])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_30"])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_31"])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_32"])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_33"])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_34"])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_35"])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_36"])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_4"])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_5"])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_6"])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_7"])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_8"])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO_9"])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_a"])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_b"])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_c"])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_d"])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_e"])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setor_f"])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha(6)"])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["split"])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGUNDA METADE"])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paga"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceita"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perdida"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação pendente"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganhadora"])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apostas"])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEM MAIS APOSTAS "])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da aposta"])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia total "])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia total da aposta"])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular recibo"])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tudo"])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A soma da aposta não pode incluir mais de 2 casas decimais"])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível cancelar o ticket:"])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível gerar o ticket: "])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi possível pagar o ticket: "])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível recarregar a posição: "])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível desembolsar a posição"])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O jogo não está vinculado "])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundos insuficientes"])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissões insuficientes"])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de segurança inválido "])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já começaram um ou mais sorteios"])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket já pago"])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi alcançado o limite de tickets cancelados"])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O valor deve estar acima de 0"])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteio"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogo"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx:"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mín:"])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não pago"])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não jogado"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar (imprimir)"])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir (cópia)"])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAR"])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIS"])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENOS"])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORA DE 2"])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORA DE 3"])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÃO VENCEDOR"])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÍMPAR"])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRE 2"])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENCEDORES"])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRE 3"])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTA"])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUINELLA"])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIFECTA"])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENCEDOR"])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VENCEDOR"])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdido"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não pago"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não jogado"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação pendente"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processo"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da aposta"])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pagamento"])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir ticket"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteio #"])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de segurança "])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividir soma"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total a pagar: "])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para a tabela "])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguinte"])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévia"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filas por página"])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desabilitado!!!"])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o idioma:"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aposta"])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status da aposta"])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia da aposta"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceita"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perdida"])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paga"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação pendente"])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelada"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganhadora"])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anular ticket"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apostado"])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogo"])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de pagamento"])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caixa"])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da aposta"])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito"])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de cancelamento "])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visão geral"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar (imprimir)"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir (cópia)"])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimir relatório"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdido"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não pago"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não jogado "])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação pendente"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em processo"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de segurança"])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar "])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apostado"])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento pendente"])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets aceitos"])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets cancelados "])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total depositado (caixa)"])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total depositado (noteiro)"])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pago"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatística do jogo"])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatística da mesa"])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento total:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botões"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear"])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear"])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloquear (stat)"])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de recargas/retiros da posição"])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados não encontrados"])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turno"])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar tudo"])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesa"])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total recarregado"])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total retirado"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrado"])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga [Noteiro]"])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga [Caixa]"])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro [Caixa]"])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado pela caixa"])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado_erro"])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagamento_bloqueado"])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (pin"])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (estatística)"])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloqueado (pendente de acesso)"])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apostando"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ativo"])}
    }
  }
}