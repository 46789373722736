import axios from 'axios';

const API_URL = process.env.VUE_APP_API+'game/';

class GameService {
    getEvents(game_id) {
        return axios.post(API_URL + 'getEvents/', game_id);
    }
    getBetAliases(alias_code) {
        return axios.post(API_URL + 'getBetAliases/', alias_code);
    }
}

export default new GameService();