import { createWebHistory, createRouter } from "vue-router";
import Login from "./components/Login.vue";
const Cashier = () => import("./components/Cashier.vue")
const Statistics = () => import("./components/Statistics.vue")
const Tables = () => import("./components/Tables.vue")
const routes = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/cashier",
    name: "cashier",
    component: Cashier,
    props: true
  },
  {
    path: "/statistics",
    name: "statistics",
    component: Statistics,
  },
  {
    path: "/tables",
    name: "tables",
    component: Tables,
  }
];

const router = createRouter({
  history: createWebHistory('#'),
  routes,
});

router.beforeEach((to, from, next) =>
{
  const publicPages = ['/login', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  if (authRequired && (!loggedIn))
  {
    next('/login');
  }
  else
  {
   next();
  }
});

export default router;