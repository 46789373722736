export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissier"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la session"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les tables"])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pari"])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36"])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00"])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18"])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Noir)"])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Rouge)"])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36"])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Noir)"])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Rouge)"])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le noir"])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne 1"])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne 2"])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne 3"])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corner"])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire"])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne"])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étrange"])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_0"])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_00"])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_1"])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_10"])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_11"])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_12"])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_13"])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_14"])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_15"])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_16"])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_17"])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_18"])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_19"])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_2"])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_20"])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_21"])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_22"])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_23"])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_24"])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_25"])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_26"])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_27"])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_28"])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_29"])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_3"])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_30"])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_31"])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_32"])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_33"])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_34"])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_35"])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_36"])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_4"])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_5"])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_6"])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_7"])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_8"])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JOUEUR_9"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouge"])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne(6)"])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noir"])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colonne1"])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colonne2"])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colonne3"])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corner"])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["douzaine1"])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["douzaine2"])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["douzaine3"])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paire"])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉGALITÉ"])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moitié1"])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Noir)"])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Rouge)"])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moitié2"])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Noir)"])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Rouge)"])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREMIÈRE MOITIÉ"])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno1"])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno10"])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno2"])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno3"])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno4"])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno5"])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno6"])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno7"])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno8"])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keno9"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ligne"])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étrange"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouge"])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_a"])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_b"])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_c"])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_d"])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_e"])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_f"])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_0"])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_00"])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_1"])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_10"])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_11"])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_12"])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_13"])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_14"])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_15"])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_16"])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_17"])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_18"])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_19"])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_2"])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_20"])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_21"])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_22"])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_23"])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_24"])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_25"])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_26"])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_27"])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_28"])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_29"])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_3"])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_30"])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_31"])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_32"])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_33"])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_34"])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_35"])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_36"])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_4"])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_5"])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_6"])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_7"])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_8"])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMÉRO_9"])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_a"])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_b"])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_c"])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_d"])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_e"])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secteur_f"])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne(6)"])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["split"])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEUXIÈME MOITIÉ"])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepté"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perte"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gagné"])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paris"])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAS PLUS DE PARIS"])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du pari"])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant du pari"])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'accord"])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le ticket"])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer tout"])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant du pari ne peut pas inclure plus de 2 décimales"])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'annulation du ticket :"])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la génération du ticket :"])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec du paiement du ticket :"])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de recharger la caisse :"])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boîte ne peut pas être retirée :"])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeu n'est pas associé"])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds insuffisants"])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["droits insuffisants"])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de sécurité est invalide"])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ou plusieurs tirages ont déjà commencé"])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket déjà payé"])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de rejets de reçus atteinte"])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être supérieure à 0"])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loterie"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max:"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min:"])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas payé"])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas joué"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer (imprimer)"])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprimer (copier)"])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAIR"])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2ÈME MOITIÉ"])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ÈRE MOITIÉ"])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAS DE PLACE"])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAS DE SHOW"])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAS DE VICTOIRE"])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPAIR"])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLACE 1-2"])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAGNANTS"])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHOW 1-3"])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXACTA"])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARI MUTUEL"])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP"])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRIFECTA"])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAGNER"])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAGNER"])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdu"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas payé"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas joué"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En procès"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date du pari"])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le reçu"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu #"])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de sécurité:"])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diviser le montant"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut "])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règlement de la totalité:"])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de données"])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets par page"])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé!!!"])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la langue:"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer la session"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pari"])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du pari"])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant du pari"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepté"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perte"])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payé"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annulé"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gagné"])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le ticket"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jalonné"])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer"])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caissier "])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date du pari"])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté"])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'annulation"])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détaillé"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer (imprimer)"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprimer (copier)"])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rapport d'impression"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdu"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas payé"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas joué"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En procès"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de sécurité:"])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["appliquer"])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revenus de paris"])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impayé"])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets acceptés"])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tickets annulés"])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total déposé (caissier)"])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total déposé (portefeuille)"])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payé"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistiques de jeu"])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["statistiques de table"])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règlement de la totalité:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer"])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutons"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verser"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoire"])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloquer"])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exprimer"])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverouiller"])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déverrouiller (stat)"])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se désister"])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La description"])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique après recharge/retrait"])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["données introuvables"])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tour"])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout afficher"])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharge totale"])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total retiré"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montré"])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger [Portefeuille]"])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger [caissier]"])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait [caissier]"])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caissier_verrouillé"])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur_verrouillée"])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement_bloqué"])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verrouillé_pin("])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verrouillé_stat"])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verrouillé_stat_pending"])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans le jeu"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fait"])}
    }
  }
}