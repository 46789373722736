<template>
  <div>{{ dateTime.hours }}:{{ dateTime.minutes }}:{{ dateTime.seconds }}</div>
</template>
<script>
import {inject} from "vue";

const date = new Date();
export default {
  name: "App",
  setup()
  {
    const ts = inject('ts');

    return {
      ts
    }
  },
  data() {
    return {
      dateTime: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      },
      timer: undefined,
      cur_epoch: this.ts,
      offset: 0
    };
  },
  methods: {
    setDateTime() {
      let date = new Date(this.ts);

      if(this.cur_epoch !== this.ts)
      {
        this.offset = 0;
        this.cur_epoch = this.ts;
      }
      this.offset = this.offset*1+1;

      date.setSeconds(date.getSeconds() + this.offset);

      let hours = date.getHours();
      let hours_t = hours.toString();
      hours_t = hours_t.padStart(2,'0');
      let minutes = date.getMinutes();
      let minutes_t = minutes.toString();
      minutes_t = minutes_t.padStart(2,'0');
      let seconds = date.getSeconds();
      let seconds_t = seconds.toString();
      seconds_t = seconds_t.padStart(2,'0');

      this.dateTime = {
        hours: hours_t,
        minutes: minutes_t,
        seconds: seconds_t,
      };
    },
  },
  beforeMount() {
    this.timer = setInterval(this.setDateTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>

</style>