<template>
  <div id="workspace">
    <div class="bet_area">
      <div id="game_bets">
        <div class="horizontals">
          <div class="mb hb" @click="setMode('h',2)" :class="{selected_b: pickMode === 'h' && pickCount === 2}">2<div></div><div></div></div>
          <div class="mb hb" @click="setMode('h',3)" :class="{selected_b: pickMode === 'h' && pickCount === 3}">3<div></div><div></div><div></div></div>
          <div class="mb hb" @click="setMode('h',4)" :class="{selected_b: pickMode === 'h' && pickCount === 4}">4<div></div><div></div><div></div><div></div></div>
          <div class="mb hb" @click="setMode('h',5)" :class="{selected_b: pickMode === 'h' && pickCount === 5}">5<div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div v-for="line in sectors" :key="line.name" class="bet_line">
          <div
              v-for="bet in line.bets"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)"
              @click="getBet(bet.value)"
          >
            {{bet.name}}
          </div>
        </div>


      </div>
      <div class="verticals_cont">
        <div class="mb mbx" @click="setMode('normal',0)">X</div>
        <div class="mb vb" @click="setMode('v',2)" :class="{selected_b: pickMode === 'v' && pickCount === 2}">2<div></div><div></div></div>
        <div class="mb vb" @click="setMode('v',3)" :class="{selected_b: pickMode === 'v' && pickCount === 3}">3<div></div><div></div><div></div></div>
        <div class="mb vb" @click="setMode('v',4)" :class="{selected_b: pickMode === 'v' && pickCount === 4}">4<div></div><div></div><div></div><div></div></div>
        <div class="mb vb" @click="setMode('v',5)" :class="{selected_b: pickMode === 'v' && pickCount === 5}">5<div></div><div></div><div></div><div></div><div></div></div>
      </div>
      <div class="controls_cont">
        <div>
        <center><span><b>Random pick:</b></span></center>
        <div class="randoms">
          <div class="bisque" @click="randomPick(1)">1</div>
          <div class="bisque" @click="randomPick(2)">2</div>
          <div class="bisque" @click="randomPick(3)">3</div>
          <div class="bisque" @click="randomPick(4)">4</div>
          <div class="bisque" @click="randomPick(5)">5</div>
          <div class="bisque" @click="randomPick(6)">6</div>
          <div class="bisque" @click="randomPick(7)">7</div>
          <div class="bisque" @click="randomPick(8)">8</div>
          <div class="bisque" @click="randomPick(9)">9</div>
          <div class="bisque" @click="randomPick(10)">10</div>
        </div>
        <div class="control_buttons">
          <div class="btn_ctrl" @click="addBet">Add bet</div>
          <div class="btn_ctrl" @click="clearBet">Clear bet</div>
        </div>
        </div>
        <div class="extras">
          <div v-if="bets_data['heads'] !== undefined && bets_data['heads']['c99']*1 > 0"
               :key="'heads'"
               :class="'bet_cell '+checkIsSelected('heads')"
               @click="getBetApply('heads')"
          >
            {{$t('cashier.betName.heads')}}
          </div>
          <div v-if="bets_data['evens'] !== undefined && bets_data['evens']['c99']*1 > 0"
               :key="'evens'"
               :class="'bet_cell '+checkIsSelected('evens')"
               @click="getBetApply('evens')"
          >
            {{$t('cashier.betName.evens')}}
          </div>
          <div v-if="bets_data['tails'] !== undefined && bets_data['tails']['c99']*1 > 0"
               :key="'tails'"
               :class="'bet_cell '+checkIsSelected('tails')"
               @click="getBetApply('tails')"
          >
            {{$t('cashier.betName.tails')}}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {inject, provide} from "vue";
import GameService from '../../services/game.service';
import cashier from "@/services/cashier.service";

export default {
  name: "Keno",
  data () {
    return {
      bets_data: [],
      selected: [],
      cur_split_selected: [],
      pickCount: 0,
      pickMode: 'normal',
      sectors:
      [
        {
          name: "line1",
          bets:
          [
            {name: 1, value: '1', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 2, value: '2', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 3, value: '3', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 4, value: '4', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 5, value: '5', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 6, value: '6', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 7, value: '7', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 8, value: '8', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 9, value: '9', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 10, value: '10', class: 'black', multiplier: '36', type: 'primitive'},
          ],

        },
        {
          name: "line2",
          bets:
          [
            {name: 11, value: '11', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 12, value: '12', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 13, value: '13', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 14, value: '14', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 15, value: '15', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 16, value: '16', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 17, value: '17', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 18, value: '18', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 19, value: '19', class: 'black', multiplier: '36', type: 'primitive'},
            {name: 20, value: '20', class: 'black', multiplier: '36', type: 'primitive'},
          ]
        },
        {
          name: "line3",
          bets:
              [
                {name: 21, value: '21', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 22, value: '22', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 23, value: '23', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 24, value: '24', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 25, value: '25', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 26, value: '26', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 27, value: '27', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 28, value: '28', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 29, value: '29', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 30, value: '30', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        },
        {
          name: "line4",
          bets:
              [
                {name: 31, value: '31', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 32, value: '32', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 33, value: '33', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 34, value: '34', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 35, value: '35', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 36, value: '36', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 37, value: '37', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 38, value: '38', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 39, value: '39', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 40, value: '40', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        },
        {
          name: "line5",
          bets:
              [
                {name: 41, value: '41', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 42, value: '42', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 43, value: '43', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 44, value: '44', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 45, value: '45', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 46, value: '46', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 47, value: '47', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 48, value: '48', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 49, value: '49', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 50, value: '50', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        },
        {
          name: "line6",
          bets:
              [
                {name: 51, value: '51', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 52, value: '52', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 53, value: '53', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 54, value: '54', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 55, value: '55', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 56, value: '56', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 57, value: '57', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 58, value: '58', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 59, value: '59', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 60, value: '60', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        },
        {
          name: "line7",
          bets:
              [
                {name: 61, value: '61', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 62, value: '62', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 63, value: '63', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 64, value: '64', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 65, value: '65', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 66, value: '66', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 67, value: '67', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 68, value: '68', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 69, value: '69', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 70, value: '70', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        },
        {
          name: "line8",
          bets:
              [
                {name: 71, value: '71', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 72, value: '72', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 73, value: '73', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 74, value: '74', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 75, value: '75', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 76, value: '76', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 77, value: '77', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 78, value: '78', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 79, value: '79', class: 'black', multiplier: '36', type: 'primitive'},
                {name: 80, value: '80', class: 'black', multiplier: '36', type: 'primitive'},
              ]
        }
      ],
      bet_aliases: []
    }
  },
  setup()
  {
    const game_kind = inject('gameKind')
    const game_alias = inject('gameAlias')
    const extra_bet = inject('gameExtra')
    const gameId = inject('gameId');
    return {
      game_kind,
      game_alias,
      extra_bet,
      gameId
    }
  },
  props: ['event', 'public_event', 'bet_value'],
  methods: {
    getBetApply(bet)
    {
      this.cur_split_selected = [];

      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.selected.splice(this.selected.indexOf(found), 1);
      }
      else
      {
        this.getBet(bet);
        this.addBet();
      }
    },
    getBet(bet)
    {
      if(this.event === '')
      {
        return;
      }

      let iter = 0;

      if(this.pickCount > 0)
      {
        this.cur_split_selected = [];
        iter = this.pickCount-1;
      }

      let even = this.event;

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 10)
        {
          let over = 0;

          for(let i = 0; i <= iter; i++)
          {
            let bb = bet;

            if(this.pickMode === 'h')
            {
              bb = bb*1+i;

              if(over > 0)
              {
                bb = Math.floor(bb/10)*10-10+over;
              }

              if(Math.floor(bb/10) === bb/10 || over > 0)
              {
                over++;
              }
            }

            if(this.pickMode === 'v')
            {
              bb = bb*1+i*10;

              if(bb > 80 || over > 0)
              {
                over++;
              }
              if(over > 0)
              {
                bb = bb-80;
              }
            }

            bb = bb.toString();
            this.cur_split_selected.push({bet: bb, event: even});
          }
        }
      }
    },
    addBet()
    {
      if(this.cur_split_selected.length === 0)
      {
        return;
      }

      let bet_value = 'keno'+this.cur_split_selected.length;
      let bet = 'keno'+this.cur_split_selected.length;
      let bet_name = '';

      for(let sel of this.cur_split_selected)
      {
        if(bet_name !== '')
        {
          bet_name = bet_name + ';';
        }
        bet_name = bet_name + sel.bet;
        bet = bet + '_' + sel.bet;
      }

      if (this.cur_split_selected.length === 1 &&(this.cur_split_selected[0]['bet'] === 'heads' || this.cur_split_selected[0]['bet'] === 'tails' || this.cur_split_selected[0]['bet'] === 'evens'))
      {
        bet_name = this.cur_split_selected[0]['bet'] ;
        bet = this.cur_split_selected[0]['bet'] ;
        bet_value = this.cur_split_selected[0]['bet'] ;
      }

      let multiplier;
      multiplier = this.bets_data[bet_value]['max']

      this.selected.push({
        bet: bet,
        event: this.event,
        public_event: this.public_event,
        bet_value: this.bet_value,
        multiplier: multiplier,
        bet_name: bet_name,
        ntt: 'n'
      });

      this.$emit('getBet', this.selected);

      this.cur_split_selected = [];
    },
    clearBet()
    {
      this.cur_split_selected = [];
    },
    sortFn(a, b)
    {
      let fa = parseInt(a.bet),
          fb = parseInt(b.bet);

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    checkIsSelected(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.cur_split_selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val)
          {
            ret += 'selected ';
          }
        }
      }

      return ret;
    },
    randomPick(cnt)
    {
      this.cur_split_selected = [];



      for(let i = 1; i <= cnt; i++)
      {
        let bet = Math.floor(Math.random() * 80+1).toString();
        let found = '';

        for (let sel of this.cur_split_selected)
        {
          if (sel.bet === bet)
          {
            found = sel;
          }
        }

        if (found === '')
        {
          this.cur_split_selected.push({
            bet: bet,
            event: this.event
          });
        }
        else
        {
          i--;
        }
      }
    },
    setMode(mode, cnt)
    {
      if(mode !== 'normal' && cnt !== 0)
      {
        this.cur_split_selected = [];
      }

      if(mode === this.pickMode && cnt === this.pickCount)
      {
        this.pickMode = 'normal';
        this.pickCount = 0;
      }
      else
      {
        this.pickMode = mode;
        this.pickCount = cnt;
      }
    },
    clear_bet()
    {
      this.selected = [];
    },
    addBetEvent(event)
    {
      if (event.keyCode == 32)
      {
        event.preventDefault();
        this.addBet();
      }
    },
    getKenoBets()
    {
      cashier.getKenoBets(this.gameId).then(
          (response) => {
            this.bets_data = response.data;
          }
    );
    }
  },
  mounted() {
    window.addEventListener('keyup', this.addBetEvent);
    this.getKenoBets();
  }
}
</script>

<style scoped>

  #workspace
  {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cornflowerblue;
  }
  #game_bets
  {
    height: 98%;
    width: 100%;
    display: grid;
    grid-template-rows: 11% 11% 11% 11% 11% 11% 11% 11% 11%;
  }
  .verticals_cont
  {
    display: flex;
    flex-direction: column;
  }
  .bet_area
  {
    height: 98%;
    width: 95%;
    display: grid;
    grid-template-columns: 60% 5% 35%;
  }
  .controls_cont
  {
    display: grid;
    grid-template-rows: 50% 50%;
  }
  .bet_cell
  {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DDDDDD;
    margin: 2px;
    cursor:pointer;
    font-size: 100%;
    font-weight: bold;
    background-color: darkgreen;
  }
  .selected
  {
    box-shadow: inset 0px 0px 0px 5px #53ea93;
    box-sizing: border-box;
    background-color: darkorchid;
    color: white;
  }
  .selected_shatters
  {
    background-color: darkkhaki;
    color: white;
  }
  .bet_line
  {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  }
  .bisque
  {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    margin: 5px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .horizontals
  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%
  }
  .verticals
  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .randoms
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .control_buttons
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_ctrl
  {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    margin: 5px;
    height: 30px;
    width: 80px;
    cursor: pointer;
  }
  .mb div
  {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    background-color: darkgreen;
  }
  .mb
  {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    background-color: darkgray;
    cursor: pointer;
  }
  .hb
  {
    height: 30px;
    margin-left: 5px;
  }
  .vb
  {
    margin-top: 5px;
    width: 30px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
  .vb div
  {
    margin-top: 2px;
  }
  .mbx
  {
    height: 30px;
    width: 30px;
    background-color: white;
    margin-left: 5px;
  }
  .selected_b
  {
    background-color: white;
  }
  .extras>div
  {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
</style>