<template>
  <div id="app">
    <div class="vld-parent">
      <loading :active="isLoading"
               :can-cancel="false"
               :is-full-page="fullPage"></loading>
    </div>
    <nav class="navbar navbar-expand navbar-dark bg-dark" v-if="isAuthorized">
      <Preload v-if="isAuthorized" ref="preload"></Preload>
      <span class="navbar-brand">{{ $t('app.cashier') }}</span>
      <button v-if="isGamesScroll" id="scroll_games_left" @click="scrollLeft"><i class="arrow left"></i></button>
      <div id="games_list" class="navbar-nav games_list" ref="games_list">
        <li v-for="game in appGames" class="nav-item" :class="checkSel(game.id)" v-bind:key="game.id" @click="setSelectedGame(game.game_type, game.id, game.name, game.game_kind, game.bet_alias_code, game.extra_bet_type)">
          <router-link to="/cashier" class="nav-link">
            {{game.name}}<span v-if="game.cnt_uncomfirmed*1 > 0" class="unc_alert">{{game.cnt_uncomfirmed}}</span>
          </router-link>
        </li>
      </div>
      <button v-if="isGamesScroll" id="scroll_games_right" @click="scrollRight"><i class="arrow right"></i></button>
      <div class="navbar-nav mr-auto">
        <li class="nav-item" :class="checkSel(-1)" @click="setSelectedGame('', -1, '', '')">
          <router-link v-if="currentUser.user_permissions.indexOf('hide_stat') === -1" to="/statistics" class="nav-link">{{ $t('app.statistics') }}</router-link>
        </li>
        <li v-if="Object.keys(tables).length > 0" class="nav-item" :class="checkSel(-2)" @click="setSelectedGame('', -2, '', '')">
          <router-link to="/tables" class="nav-link">{{ $t('app.tables') }}</router-link>
        </li>
      </div>
      <div class="navbar-nav ml-auto">
        <label v-if="is_admin" class="nav-link">view codes <input type="checkbox" @change="show_codes"></label>
        <Clock class="nav-link"></Clock>
        <div v-if="currentUser.user_permissions.indexOf('hide_balance') === -1" class="nav-link">{{ $t('app.balance')}} ({{currency_symbol}}) {{balance}}</div>
        <div class="nav-link">{{currentUser.username}}<span v-if="all_unc*1 > 0" class="unc_alert">{{all_unc}}</span></div>
        <li class="nav-item">
          <a class="nav-link" @click.prevent="logOut()">
            <font-awesome-icon icon="sign-out-alt" />{{ $t('app.logout')}}</a>
        </li>
        <!--LanguageSwitcher></LanguageSwitcher-->
      </div>
    </nav>
    <div class="vue_app_container">
      <router-view v-if="gameId !== 0 || !isAuthorized" :key="$route.fullPath+gameId" />
    </div>
  </div>
</template>

<script>
import {ref, provide, computed, watchEffect, onMounted, nextTick} from "vue";
import Clock from './components/Clock';
import {useStore} from "vuex";
import {useRouter, useRoute} from 'vue-router'
import Preload from "@/components/Preload";
import authService from './services/auth.service';
import { getCurrentInstance } from 'vue'
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  components:
  {
    Preload,
    Clock,
    Loading
  },
  setup()
  {
    onMounted(() => {
      nextTick(function () {
        checkGamesScroll()
      })
    })

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const internalInstance = getCurrentInstance()
    const globalProperties=internalInstance.appContext.config.globalProperties
    const $i18n=globalProperties.$i18n
    const sessionLocale = ref('');
    const isLoading = ref(false);
    const fullPage = ref(true);

    const ver = ref(0)
    function verifyVer(nver)
    {
      if(ver.value>0 && ver.value!=nver) {
        location.reload()
      }

      ver.value=nver
    }
    provide('verifyVer', verifyVer)

    const setIsLoading = (mode) => {
      isLoading.value = mode;
    }

    const games_list = ref(null);

    const scrollLeft = () => {
      games_list.value.scrollLeft -= 150;
    }

    const scrollRight = () => {
      games_list.value.scrollLeft += 150;
    }

    provide('setIsLoading', setIsLoading)

    watchEffect(() => {
      if($i18n.locale !== 'code')
      {
        sessionLocale.value = $i18n.locale;
      }
    })

    provide('$i18n', $i18n)
    const currentUser = computed(() => store.state.auth.user)
    provide('currentUser', currentUser)

    const isAuthorized = computed(function ()
    {
      let currentSession = JSON.parse(sessionStorage.getItem('user'));
      return currentUser.value !== undefined
          && currentUser.value !== null
          && currentUser.value.accessToken !== undefined
          && currentUser.value.accessToken !== null
          && currentUser.value.accessToken !== ''
          && currentSession !== undefined
          && currentSession !== null
          && currentSession.accessToken !== undefined
          && currentSession.accessToken !== null
          && currentSession.accessToken !== '';
    })

    const all_unc = computed(function ()
    {
      let cnt_unc = 0;

      for(let game of appGames.value)
      {
        cnt_unc = cnt_unc+game.cnt_uncomfirmed*1;
      }
      return cnt_unc;
    })

    const balance = ref(0);
    const ts = ref();
    const setBalance = (data) => {
      balance.value = data.balance;
      ts.value = data.ts;
    }

    const view_receipt_bets = ref([]);
    provide('view_receipt_bets', view_receipt_bets)

    const setViewReceiptBets = (bets) => {
      view_receipt_bets.value = bets;
    }
    provide('setViewReceiptBets', setViewReceiptBets)

    const currency_symbol = ref('');
    const currency_iso = ref('');
    const setCurrencySymbol = (symbol, iso) => {
      currency_symbol.value = symbol;
      currency_iso.value = iso;
    }
    const appGames = ref('');

    const gameId = ref(0);
    const gameType = ref('');
    const gameName = ref('');
    const gameKind = ref('');
    const gameAlias = ref('');
    const gameExtra = ref('');

    const timer = ref(undefined);
    const setSelectedGame = (type, id, name, kind, alias, extra_type) =>
    {
      if(id === -1)
      {
        gameId.value = id;
        router.push('statistics')
      }
      else if(id === -2)
      {
        gameId.value = id;
        router.push('tables')
      }
      else {
        gameType.value = type;
        gameId.value = id;
        gameName.value = name;
        gameKind.value = kind;
        gameAlias.value = alias;
        gameExtra.value = extra_type;
        setIsUpBalance();
      }
    }

    const setGame = (game) => {
      appGames.value = game;

      for(let cgame of appGames.value)
      {
        if(cgame.id == gameId.value)
        {
          gameKind.value = cgame.game_kind;
        }

        if(cgame.id == gameId.value)
        {
          gameExtra.value = cgame.extra_bet_type;
        }
      }
    }

    const isUpBalance = ref(true);

    const setIsUpBalance = () =>
    {
      isUpBalance.value = !isUpBalance.value;
    }

    const isGamesScroll = ref(false);

    const checkGamesScroll = () =>
    {
      let div = document.querySelector('#games_list');
      if(div !== null)
      {
        let hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
        isGamesScroll.value = hasHorizontalScrollbar;
      }
    }

    provide('checkGamesScroll', checkGamesScroll);

    const logOut = () =>
    {
      $i18n.locale = detectLanguage()||'en';
      authService.logout();
      store.dispatch('auth/logout');
      router.push('/login');
    }

    const set_result = function (code)
    {
      if(code=='401')
      {
        logOut()
      }
    }

    const detectLanguage = () =>
    {
      const lng = window.navigator.userLanguage || window.navigator.language;
      const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const lang = locales
          .keys()
          .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));
      return lang ? lang.replace("./", "").replace(".json", "") : null;
    }

    const checkSel = (id) =>
    {
      let ret_class = '';

      if((id === -1 && route.name === 'statistics') || (id === -2 && route.name === 'tables'))
      {
        ret_class = 'nav_selected';
      }
      if(id === gameId.value && route.name !== 'statistics' && route.name !== 'tables')
      {
        ret_class = 'nav_selected';
      }

      return ret_class;
    }

    const bet_buttons = ref([]);
    const bet_value = ref('');

    const admins = ['cashpenvik', 'cashpen2']
    const is_admin = computed(() => {
      let res_acces = false;

      if(admins.includes(currentUser.value.username)) {
        res_acces = true
      }

      return res_acces;
    })
    const show_codes = (e) =>
    {
      if(e.target.checked)
      {
        $i18n.locale = "code";
        localStorage.setItem("lang", "code");
      }
      else
      {
        $i18n.locale = sessionLocale.value;
        localStorage.setItem("lang", sessionLocale.value);
      }
    }

    const preload = ref(null);

    const updateBalance = () =>
    {
      preload.value.getBalance();
    }

    const updateTables = () =>
    {
      preload.value.getTables();
    }

    const tables = ref([]);

    const setTables = (tables_obj) =>
    {
      tables.value = tables_obj;
    }

    const $t=globalProperties.$t

    function zoomApp()
    {
      var winwidth = window.innerWidth;
      var winheight = window.innerHeight;
      var sx = winwidth / 1920;
      var sy = winheight / 1080;

      var	_k = sx>sy ? sy:sx;

      document.querySelector('#app').style.transform = "scale("+_k+")"
      document.querySelector('#app').style.transformOrigin = "left top"
      document.querySelector('#app').style.width = "calc(100%/"+_k+")"
      document.querySelector('#app').style.height = "calc(100%/"+_k+")"
    }

    function mountedFn()
    {
      zoomApp()
      window.addEventListener('resize', zoomApp)
    }

    onMounted(mountedFn)

    provide('set_result', set_result)
    provide('currency_symbol',currency_symbol);
    provide('currency_iso',currency_iso);
    provide('appGames',appGames);
    provide('setBalance',setBalance);
    provide('setCurrencySymbol',setCurrencySymbol);
    provide('gameId',gameId);
    provide('gameType',gameType);
    provide('gameName',gameName);
    provide('gameKind',gameKind);
    provide('gameAlias',gameAlias);
    provide('gameExtra',gameExtra);
    provide('ts',ts);
    provide('setSelectedGame',setSelectedGame);
    provide('setGame',setGame);
    provide('setBalance',setBalance);
    provide('isUpBalance',isUpBalance);
    provide('setIsUpBalance',setIsUpBalance);
    provide('logOut',logOut);
    provide('route',route);
    provide('timer',timer);
    provide('bet_buttons',bet_buttons);
    provide('bet_value',bet_value);
    provide('isAuthorized', isAuthorized);
    provide('updateBalance',updateBalance);
    provide('setTables',setTables);
    provide('tables',tables);
    provide('updateTables',updateTables);
    provide('$t',$t);

    return {
      balance,
      currency_symbol,
      appGames,
      ts,
      gameId,
      gameType,
      gameName,
      setSelectedGame,
      isAuthorized,
      currentUser,
      logOut,
      checkSel,
      route,
      is_admin,
      show_codes,
      isLoading,
      fullPage,
      games_list,
      scrollLeft,
      scrollRight,
      isGamesScroll,
      preload,
      tables,
      all_unc
    };
  }
};
</script>
<style>
  html, body, div#app
  {
    width: 100%;
    height: 100%;
    min-height: 720px;
    min-width: 1024px;
    margin: 0px 0px 0px 0px;
  }
  .vue_app_container
  {
    height: 95%;
  }
  .navbar
  {
    height: 4%;
  }
  .nav_selected
  {
    background-color: white !important;
  }
  .nav_selected a
  {
    color: black !important;

  }
  .navbar-expand .navbar-nav
  {
    flex-direction: row;
    white-space: nowrap;
  }
  .dts_row:hover
  {
    background-color: #CCCCCC;
    cursor: pointer;
  }
  .view_codes
  {
    height: 95%;
    top: 10%;
  }
  .games_list
  {
    max-width: 50%;
    overflow: hidden;
  }
  #scroll_games_left
  {
    margin-right: 10px;
  }
  #scroll_games_right
  {
    margin-left: 10px;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .unc_alert
  {
    border-radius: 50%;
    margin-left: 3px;
    display: inline-block;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    vertical-align: middle;
    color: white;
    background-color: #ff4033;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 1em;
  }
</style>