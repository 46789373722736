<template>
  <div>
    <div v-if="fight_bets_obj.winner1 != undefined">
      <div class="bets_row winners">
        <button class="bet bet_red" :class="checkIsSelected('winner1')" @click="getOneBet('winner1', fight_bets_obj.winner1)">1<br><span>{{convert_coef(fight_bets_obj.winner1, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet" :class="checkIsSelected('draw')" @click="getOneBet('draw', fight_bets_obj.draw)">X<br><span>{{convert_coef(fight_bets_obj.draw, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2')" @click="getOneBet('winner2', fight_bets_obj.winner2)">2<br><span>{{convert_coef(fight_bets_obj.winner2, fight_bets_obj.coef_type)}}</span></button>
      </div>
      <div class="bets_row over_under">
        <button class="bet" :class="checkIsSelected('time_over')" @click="getOneBet('time_over', fight_bets_obj.time_over)">Over<br><span>{{convert_coef(fight_bets_obj.time_over, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet" :class="checkIsSelected('time_under')" @click="getOneBet('time_under', fight_bets_obj.time_under)">Under<br><span>{{convert_coef(fight_bets_obj.time_under, fight_bets_obj.coef_type)}}</span></button>
      </div>
      <div class="bets_row sectors">
        <button class="bet" :class="checkIsSelected('win-sector_1')" @click="getOneBet('win-sector_1', fight_bets_obj.win_sector_1)">Sector 1<br><span>{{convert_coef(fight_bets_obj.win_sector_1, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet" :class="checkIsSelected('win-sector_2')" @click="getOneBet('win-sector_2', fight_bets_obj.win_sector_2)">Sector 2<br><span>{{convert_coef(fight_bets_obj.win_sector_2, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet" :class="checkIsSelected('win-sector_3')" @click="getOneBet('win-sector_3', fight_bets_obj.win_sector_3)">Sector 3<br><span>{{convert_coef(fight_bets_obj.win_sector_3, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet" :class="checkIsSelected('win-sector_4')" @click="getOneBet('win-sector_4', fight_bets_obj.win_sector_4)">Sector 4<br><span>{{convert_coef(fight_bets_obj.win_sector_4, fight_bets_obj.coef_type)}}</span></button>
      </div>
      <div class="bets_row red_bets">
        <button class="bet bet_red" :class="checkIsSelected('winner1_time_over')" @click="getOneBet('winner1_time_over', fight_bets_obj.winner1_time_over)">Over<br><span>{{convert_coef(fight_bets_obj.winner1_time_over, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_red" :class="checkIsSelected('winner1_time_under')" @click="getOneBet('winner1_time_under', fight_bets_obj.winner1_time_under)">Under<br><span>{{convert_coef(fight_bets_obj.winner1_time_under, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_red" :class="checkIsSelected('winner1_sector1')" @click="getOneBet('winner1_sector1', fight_bets_obj.winner1_sector1)">Sector 1<br><span>{{convert_coef(fight_bets_obj.winner1_sector1, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_red" :class="checkIsSelected('winner1_sector2')" @click="getOneBet('winner1_sector2', fight_bets_obj.winner1_sector2)">Sector 2<br><span>{{convert_coef(fight_bets_obj.winner1_sector2, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_red" :class="checkIsSelected('winner1_sector3')" @click="getOneBet('winner1_sector3', fight_bets_obj.winner1_sector3)">Sector 3<br><span>{{convert_coef(fight_bets_obj.winner1_sector3, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_red" :class="checkIsSelected('winner1_sector4')" @click="getOneBet('winner1_sector4', fight_bets_obj.winner1_sector4)">Sector 4<br><span>{{convert_coef(fight_bets_obj.winner1_sector4, fight_bets_obj.coef_type)}}</span></button>
      </div>
      <div class="bets_row blue_bets">
        <button class="bet bet_blue" :class="checkIsSelected('winner2_time_over')" @click="getOneBet('winner2_time_over', fight_bets_obj.winner2_time_over)">Over<br><span>{{convert_coef(fight_bets_obj.winner2_time_over, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2_time_under')" @click="getOneBet('winner2_time_under', fight_bets_obj.winner2_time_under)">Under<br><span>{{convert_coef(fight_bets_obj.winner2_time_under, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2_sector1')" @click="getOneBet('winner2_sector1', fight_bets_obj.winner2_sector1)">Sector 1<br><span>{{convert_coef(fight_bets_obj.winner2_sector1, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2_sector2')" @click="getOneBet('winner2_sector2', fight_bets_obj.winner2_sector2)">Sector 2<br><span>{{convert_coef(fight_bets_obj.winner2_sector2, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2_sector3')" @click="getOneBet('winner2_sector3', fight_bets_obj.winner2_sector3)">Sector 3<br><span>{{convert_coef(fight_bets_obj.winner2_sector3, fight_bets_obj.coef_type)}}</span></button>
        <button class="bet bet_blue" :class="checkIsSelected('winner2_sector4')" @click="getOneBet('winner2_sector4', fight_bets_obj.winner2_sector4)">Sector 4<br><span>{{convert_coef(fight_bets_obj.winner2_sector4, fight_bets_obj.coef_type)}}</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, registerRuntimeCompiler} from "vue";

export default {
  name: "Fight",
  data () {
    return {
      bet_type: 'winner',
      bet_switch: true,
      main_bets: [],
      exacta_bets: [],
      extra_bets: [],
      selected: [],
      cur_split_selected: [],
      pickCount: 0
    }
  },
  setup()
  {
    const game_kind = inject('gameKind')
    const game_alias = inject('gameAlias')
    const extra_bet = inject('gameExtra')
    const fight_bets_obj = inject('fight_bets_obj')
    const convert_coef = inject('convert_coef');

    return {
      game_kind,
      game_alias,
      extra_bet,
      fight_bets_obj,
      convert_coef
    }
  },
  props: ['event', 'public_event', 'bet_value'],
  emits: ['splitBets', 'getBet'],
  methods: {
    getBet(bet)
    {
      if(this.event === '')
      {
        return;
      }

      let iter = 0;

      if(this.pickCount > 0)
      {
        this.cur_split_selected = [];
        iter = this.pickCount-1;
      }

      let even = this.event;

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 10)
        {
          let over = 0;

          for(let i = 0; i <= iter; i++)
          {
            let bb = bet;

            if(this.pickMode === 'h')
            {
              bb = bb*1+i;

              if(over > 0)
              {
                bb = Math.floor(bb/10)*10-10+over;
              }

              if(Math.floor(bb/10) === bb/10 || over > 0)
              {
                over++;
              }
            }

            if(this.pickMode === 'v')
            {
              bb = bb*1+i*10;

              if(bb > 80 || over > 0)
              {
                over++;
              }
              if(over > 0)
              {
                bb = bb-80;
              }
            }

            bb = bb.toString();
            this.cur_split_selected.push({bet: bb, event: even});
          }
        }
      }
    },
    addBet()
    {
      if(this.cur_split_selected.length === 0)
      {
        return;
      }

      let bet = 'keno'+this.cur_split_selected.length;
      let bet_name = '';

      for(let sel of this.cur_split_selected)
      {
        if(bet_name !== '')
        {
          bet_name = bet_name + ';';
        }
        bet_name = bet_name + sel.bet;
        bet = bet + '_' + sel.bet;
      }

      this.selected.push({
        bet: bet,
        event: this.event,
        public_event: this.public_event,
        bet_value: this.bet_value,
        multiplier: 1,
        bet_name: bet_name,
        ntt: 'n',
        coef_type: this.fight_bets_obj.coef_type
      });

      this.$emit('getBet', this.selected);

      this.cur_split_selected = [];
    },
    getOneBet(bet, multi)
    {
      let bet_name = bet;
      let even = this.event;
      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.selected.splice(this.selected.indexOf(found), 1);
      }
      else
      {
        this.selected.push({
          bet: bet,
          event: this.event,
          public_event: this.public_event,
          bet_value: this.bet_value,
          multiplier: multi,
          bet_name: bet_name,
          ntt: 'n',
          coef_type: this.fight_bets_obj.coef_type
        });
      }

      this.$emit('getBet', this.selected);
    },
    getQuinBet(f, s)
    {
      let m;
      m = this.exacta_bets['p'+f]['p'+s];
      this.getOneBet('exacta_'+f+'_'+s, m);
      m = this.exacta_bets['p'+s]['p'+f];
      this.getOneBet('exacta_'+s+'_'+f, m);
      //this.$emit('splitBets');
    },
    getTrifRevBet(f, s, t)
    {
      let m;
      m = this.exacta_bets['p'+f]['p'+s];
      this.getOneBet('exacta_'+f+'_'+s, m);
      m = this.exacta_bets['p'+s]['p'+f];
      this.getOneBet('exacta_'+s+'_'+f, m);
      m = this.exacta_bets['p'+f]['p'+s];
      this.getOneBet('exacta_'+f+'_'+s, m);
      m = this.exacta_bets['p'+s]['p'+f];
      this.getOneBet('exacta_'+s+'_'+f, m);
      m = this.exacta_bets['p'+f]['p'+s];
      this.getOneBet('exacta_'+f+'_'+s, m);
      m = this.exacta_bets['p'+s]['p'+f];
      this.getOneBet('exacta_'+s+'_'+f, m);
      //this.$emit('splitBets');
    },
    clearBet()
    {
      this.cur_split_selected = [];
    },
    sortFn(a, b)
    {
      let fa = parseInt(a.bet),
          fb = parseInt(b.bet);

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    checkIsSelected(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val)
          {
            ret += 'selected ';
          }
        }
      }

      return ret;
    },
    setMode(mode, cnt)
    {
      if(mode !== 'normal' && cnt !== 0)
      {
        this.cur_split_selected = [];
      }

      if(mode === this.pickMode && cnt === this.pickCount)
      {
        this.pickMode = 'normal';
        this.pickCount = 0;
      }
      else
      {
        this.pickMode = mode;
        this.pickCount = cnt;
      }
    },
    clear_bet()
    {
      this.selected = [];
    },
    addBetEvent(event)
    {
      if (event.keyCode == 32)
      {
        event.preventDefault();
        this.addBet();
      }
    },
    setBetType(type)
    {
      this.bet_type = type;
    },
    setTrifectaTb(tb)
    {
      this.trifecta_tb = tb;
    },
    cutKeyP(key)
    {
      return key.replace('p', '')
    },
    checkWinnerBets(bet)
    {
      if(this.bet_switch === false)
      {
        return 'no-'+bet;
      }

      return bet;
    },
    checkWinnerBetsObj(bet)
    {
      if(this.bet_switch === false)
      {
        return 'no_'+bet;
      }

      return bet;
    },
    decTb()
    {
      if (this.trifecta_tb === 1)
      {
        this.trifecta_tb = 6
      }
      else
      {
        this.trifecta_tb--;
      }
    },
    incTb()
    {
      if (this.trifecta_tb === 6)
      {
        this.trifecta_tb = 1
      }
      else
      {
        this.trifecta_tb++;
      }
    }
  },
  watch:
  {
    race6_bets_obj(newVal,oldVal) {
      this.main_bets = this.race6_bets_obj.main_bets
      this.exacta_bets = this.race6_bets_obj.exacta_bets
      this.extra_bets = this.race6_bets_obj.extra_bets

      for (let i=1; i <= 6; i++)
      {
        let cur_tfb_obj = this.race6_bets_obj['trifecta_bets_'+i];
        let s_arr = []

        for (let f in cur_tfb_obj)
        {
          for (let s in cur_tfb_obj[f])
          {
            if (cur_tfb_obj[f][s] !== '-')
            {
              s_arr.push({p2: this.cutKeyP(f), p3: this.cutKeyP(s)})
            }
          }
        }

        this.tb_template['p'+i] = s_arr
      }
    }
  },
  computed:
  {
    trifecta_bets() {
      let obj_name = 'p'+this.trifecta_tb+1;
      return this.race6_bets_obj['trifecta_bets_'+this.trifecta_tb];
    }
  }

}
</script>

<style scoped>
  .bets_row
  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 5px;
  }
  .bet
  {
    width: 100px;
    margin-left: 10px;
    height: 50px;
    font-weight: bold;
  }
  .bet_red
  {
    background-color: red;
    color: white;
  }
  .bet_blue
  {
    background-color: blue;
    color: white;
  }
  .selected {
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
    background-color: #faa732;
    background-image: linear-gradient(180deg, #fbb450, #f89406);
    background-repeat: repeat-x;
    border-color: #f89406 #f89406 #ad6704;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
  }

</style>