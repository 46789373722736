<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <div class="row">
        <div class="col">{{ $t('login.chooseLanguage') }}</div>
        <div class="col">
          <LanguageSwitcher></LanguageSwitcher>
        </div>
      </div>
      <div class="form-group">
        <label for="username">{{ $t('login.username') }}</label>
        <input v-model="username" name="username" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label for="password">{{ $t('login.password') }}</label>
        <input v-model="password" name="password" type="password" class="form-control" />
      </div>

      <div class="form-group">
        <button @click="handleLogin" class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>{{ $t('login.login') }}</span>
        </button>
      </div>

      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
  name: "Login",
  components: {
    LanguageSwitcher
  },
  setup()
  {
    const logOut = inject('logOut');

    return {
      logOut
    }
  },
  data() {
    const username = ref('')
    const password = ref('')

    return {
      loading: false,
      message: "",
      username,
      password
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/cashier");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if(this.username === '')
      {
        this.message = 'Invalid username or password!';
        this.loading = false;
        return;
      }

      let user = {username: this.username, password: this.password}

      this.$store.dispatch("auth/login", user).then(
        (response) => {
          if(response.accessToken)
          {
            if(response.roles.includes('cashier'))
            {
              this.$router.push("/cashier");
            }
            else
            {
              this.loading = false;
              this.message = 'Permission denied';
              this.logOut();
            }
          }
          else
          {
            this.loading = false;
            this.message = response.message;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 390px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
