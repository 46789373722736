<template>
  <div id="workspace">
    <div class="bet_area">
      <div id="game_bets">
        <div v-for="line in sectors" :key="line.name" class="bet_line">
          <div
              v-for="bet in line.bets"
              :key="bet.value"
              :class="'bet_cell '+bet.class+' '+checkIsSelected(bet.value)"
              @click="getBet(bet.value)"
          >
            {{bet.name}}
          </div>
        </div>
        <div class="bet_line">
          <div
              v-for="choose in columnChooser"
              :key="choose.value"
              :class="'bet_cell '+choose.class+checkIsSelectedColorCol(choose.value)"
              @click="chooseColumn(choose.value)"
          >
          </div>
        </div>
        <div></div>
        <div class="bet_hint">First ball color:</div>
        <div class="bet_line">
          <div
              v-for="choose in columnChooser"
              :key="choose.value"
              :class="'bet_cell '+choose.class+checkIsSelectedColorF(choose.value)"
              @click="firstColor(choose.value)"
          >
          </div>
        </div>
        <div class="bet_hint">Last ball color:</div>
        <div class="bet_line">
          <div
              v-for="choose in columnChooser"
              :key="choose.value"
              :class="'bet_cell '+choose.class+checkIsSelectedColorL(choose.value)"
              @click="lastColor(choose.value)"
          >
          </div>
        </div>
      </div>
      <div class="controls_cont">
        <div class="add_cont">
          <div class="cur_nums" v-html="cur_nums"></div>
          <div class="control_buttons">
            <div
              class="btn_ctrl"
              @click="addBet"
              :class="{disAdd: checkDisAdd()}"
            >Add bet</div>
            <div class="btn_ctrl" @click="clearBet">Clear bet</div>
          </div>
        </div>
        <div class="abets">
          <div class="abets_half">
            <div class="btn_abet" v-for="abet in additionalBets1" v-bind:key="abet.bet" @click="addAbet(abet)" :class="checkIsSelectedAdd(abet.bet)">{{abet.name}}</div>
          </div>
          <div class="abets_half">
            <div class="btn_abet" v-for="abet in additionalBets2" v-bind:key="abet.bet" @click="addAbet(abet)" :class="checkIsSelectedAdd(abet.bet)">{{abet.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";
import GameService from '../../services/game.service';

export default {
  name: "Lucky6",
  data () {
    return {
      selected: [],
      cur_split_selected: [],
      pickCount: 0,
      pickMode: 'normal',
      sectors:
      [
        {
          name: "line1",
          bets:
          [
            {name: 1, value: '1', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 2, value: '2', class: 'green', multiplier: '36', type: 'primitive'},
            {name: 3, value: '3', class: 'blue', multiplier: '36', type: 'primitive'},
            {name: 4, value: '4', class: 'violet', multiplier: '36', type: 'primitive'},
            {name: 5, value: '5', class: 'brown', multiplier: '36', type: 'primitive'},
            {name: 6, value: '6', class: 'yellow', multiplier: '36', type: 'primitive'},
            {name: 7, value: '7', class: 'orange', multiplier: '36', type: 'primitive'},
            {name: 8, value: '8', class: 'black', multiplier: '36', type: 'primitive'}
          ],

        },
        {
          name: "line2",
          bets:
          [
            {name: 9, value: '9', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 10, value: '10', class: 'green', multiplier: '36', type: 'primitive'},
            {name: 11, value: '11', class: 'blue', multiplier: '36', type: 'primitive'},
            {name: 12, value: '12', class: 'violet', multiplier: '36', type: 'primitive'},
            {name: 13, value: '13', class: 'brown', multiplier: '36', type: 'primitive'},
            {name: 14, value: '14', class: 'yellow', multiplier: '36', type: 'primitive'},
            {name: 15, value: '15', class: 'orange', multiplier: '36', type: 'primitive'},
            {name: 16, value: '16', class: 'black', multiplier: '36', type: 'primitive'}
          ]
        },
        {
          name: "line3",
          bets:
          [
            {name: 17, value: '17', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 18, value: '18', class: 'green', multiplier: '36', type: 'primitive'},
            {name: 19, value: '19', class: 'blue', multiplier: '36', type: 'primitive'},
            {name: 20, value: '20', class: 'violet', multiplier: '36', type: 'primitive'},
            {name: 21, value: '21', class: 'brown', multiplier: '36', type: 'primitive'},
            {name: 22, value: '22', class: 'yellow', multiplier: '36', type: 'primitive'},
            {name: 23, value: '23', class: 'orange', multiplier: '36', type: 'primitive'},
            {name: 24, value: '24', class: 'black', multiplier: '36', type: 'primitive'}
          ]
        },
        {
          name: "line4",
          bets:
          [
            {name: 25, value: '25', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 26, value: '26', class: 'green', multiplier: '36', type: 'primitive'},
            {name: 27, value: '27', class: 'blue', multiplier: '36', type: 'primitive'},
            {name: 28, value: '28', class: 'violet', multiplier: '36', type: 'primitive'},
            {name: 29, value: '29', class: 'brown', multiplier: '36', type: 'primitive'},
            {name: 30, value: '30', class: 'yellow', multiplier: '36', type: 'primitive'},
            {name: 31, value: '31', class: 'orange', multiplier: '36', type: 'primitive'},
            {name: 32, value: '32', class: 'black', multiplier: '36', type: 'primitive'}
          ]
        },
        {
          name: "line5",
          bets:
          [
            {name: 33, value: '33', class: 'red', multiplier: '36', type: 'primitive'},
            {name: 34, value: '34', class: 'green', multiplier: '36', type: 'primitive'},
            {name: 35, value: '35', class: 'blue', multiplier: '36', type: 'primitive'},
            {name: 36, value: '36', class: 'violet', multiplier: '36', type: 'primitive'},
            {name: 37, value: '37', class: 'brown', multiplier: '36', type: 'primitive'},
            {name: 38, value: '38', class: 'yellow', multiplier: '36', type: 'primitive'},
            {name: 39, value: '39', class: 'orange', multiplier: '36', type: 'primitive'},
            {name: 40, value: '40', class: 'black', multiplier: '36', type: 'primitive'}
          ]
        },
        {
          name: "line6",
          bets:
              [
                {name: 41, value: '41', class: 'red', multiplier: '36', type: 'primitive'},
                {name: 42, value: '42', class: 'green', multiplier: '36', type: 'primitive'},
                {name: 43, value: '43', class: 'blue', multiplier: '36', type: 'primitive'},
                {name: 44, value: '44', class: 'violet', multiplier: '36', type: 'primitive'},
                {name: 45, value: '45', class: 'brown', multiplier: '36', type: 'primitive'},
                {name: 46, value: '46', class: 'yellow', multiplier: '36', type: 'primitive'},
                {name: 47, value: '47', class: 'orange', multiplier: '36', type: 'primitive'},
                {name: 48, value: '48', class: 'black', multiplier: '36', type: 'primitive'}
              ]
        }
      ],
      columnChooser:
      [
        {name: 'red', value: 'red', class: 'red', multiplier: '36', type: 'primitive'},
        {name: 'green', value: 'green', class: 'green', multiplier: '36', type: 'primitive'},
        {name: 'blue', value: 'blue', class: 'blue', multiplier: '36', type: 'primitive'},
        {name: 'violet', value: 'violet', class: 'violet', multiplier: '36', type: 'primitive'},
        {name: 'brown', value: 'brown', class: 'brown', multiplier: '36', type: 'primitive'},
        {name: 'yellow', value: 'yellow', class: 'yellow', multiplier: '36', type: 'primitive'},
        {name: 'orange', value: 'orange', class: 'orange', multiplier: '36', type: 'primitive'},
        {name: 'black', value: 'black', class: 'black', multiplier: '36', type: 'primitive'}
      ],
      additionalBets1:
      [
        {name: 'First ball is even', bet: 'first_ball_is_even', altName: 'First ball is even'},
        {name: 'First ball is odd', bet: 'first_ball_is_odd', altName: 'First ball is odd'},
        {name: 'Last ball is even', bet: 'last_ball_is_even', altName: 'Last ball is even'},
        {name: 'Last ball is odd', bet: 'last_ball_is_odd', altName: 'Last ball is odd'},
        {name: 'First five balls are even leading', bet: 'first_five_even_leading', altName: 'First five balls are even leading'},
        {name: 'First five balls are odd leading', bet: 'first_five_odd_leading', altName: 'First five balls are odd leading'}
      ],
      additionalBets2:
      [
        {name: 'Sum of first five balls is more than 122.5', bet: 'first_five_sum_more_122.5', altName: 'Sum of first five balls > 122.5'},
        {name: 'Sum of first five balls is less than 122.5', bet: 'first_five_sum_less_122.5', altName: 'Sum of first five balls < 122.5'},
        {name: 'First ball is larger than 24.5', bet: 'first_ball_larger_24.5', altName: 'First ball is larger than 24.5'},
        {name: 'First ball is smaller than 24.5', bet: 'first_ball_smaller_24.5', altName: 'First ball is smaller than 24.5'},
        {name: 'Last ball is larger than 24.5', bet: 'last_ball_larger_24.5', altName: 'Last ball is larger than 24.5'},
        {name: 'Last ball is smaller than 24.5', bet: 'last_ball_smaller_24.5', altName: 'Last ball is smaller than 24.5'}
      ],
      bet_aliases: [],
      cur_mode: ''
    }
  },
  setup()
  {
    const game_kind = inject('gameKind')
    const game_alias = inject('gameAlias')
    const extra_bet = inject('gameExtra')

    return {
      game_kind,
      game_alias,
      extra_bet
    }
  },
  props: ['event', 'public_event', 'bet_value'],
  methods: {
    getBet(bet)
    {
      if(this.cur_mode != 'main' && this.cur_mode !== '')
      {
        this.cur_split_selected = [];
      }

      this.cur_mode = 'main';

      if(this.event === '')
      {
        return;
      }

      let iter = 0;

      if(this.pickCount > 0)
      {
        this.cur_split_selected = [];
        iter = this.pickCount-1;
      }

      let even = this.event;

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 10)
        {
          let over = 0;

          for(let i = 0; i <= iter; i++)
          {
            let bb = bet;

            if(this.pickMode === 'h')
            {
              bb = bb*1+i;

              if(over > 0)
              {
                bb = Math.floor(bb/10)*10-10+over;
              }

              if(Math.floor(bb/10) === bb/10 || over > 0)
              {
                over++;
              }
            }

            if(this.pickMode === 'v')
            {
              bb = bb*1+i*10;

              if(bb > 80 || over > 0)
              {
                over++;
              }
              if(over > 0)
              {
                bb = bb-80;
              }
            }

            bb = bb.toString();
            this.cur_split_selected.push({bet: bb, event: even});
          }
        }
      }
    },
    addBet()
    {
      let cssl = this.cur_split_selected.length;
      let cm = this.cur_mode;

      if((cssl < 6 && cm === 'main') || (cm != 'main' && (cssl === 0 || cssl === 3)))
      {
        return;
      }

      let bet = '';
      let bet_name = '';

      switch (this.cur_mode)
      {
        case 'main':
          bet = 'lucky'+this.cur_split_selected.length;
          bet_name = '';
          break;
        case 'fcolor':
          bet = 'first-ball-color';
          bet_name = 'F. color: ';
          break;
        case 'lcolor':
          bet = 'last-ball-color';
          bet_name = 'L. color: ';
          break;
      }



      for(let sel of this.cur_split_selected)
      {
        if(bet_name !== '' && bet_name !== 'F. color: ' && bet_name !== 'L. color: ')
        {
          bet_name = bet_name + ';';
        }
        bet_name = bet_name + sel.bet;
        bet = bet + '_' + sel.bet;
      }

      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found === '')
      {
        this.selected.push({
          bet: bet,
          event: this.event,
          public_event: this.public_event,
          bet_value: this.bet_value,
          multiplier: 1,
          bet_name: bet_name,
          ntt: 'n'
        });
      }

      this.$emit('getBet', this.selected);

      this.cur_split_selected = [];
    },
    clearBet()
    {
      this.cur_split_selected = [];
    },
    sortFn(a, b)
    {
      let fa = parseInt(a.bet),
          fb = parseInt(b.bet);

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    },
    checkIsSelected(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.cur_split_selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val)
          {
            ret += 'selected ';
          }
        }
      }

      return ret;
    },
    checkIsSelectedAdd(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val)
          {
            ret += 'selectedAdd ';
          }
        }
      }

      return ret;
    },
    checkIsSelectedColorF(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.cur_split_selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val && this.cur_mode === 'fcolor')
          {
            ret += ' selected';
          }
        }
      }

      return ret;
    },
    checkIsSelectedColorL(val)
    {
      let event = this.event;
      let ret = '';

      for(let sel of this.cur_split_selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === val && this.cur_mode === 'lcolor')
          {
            ret += ' selected';
          }
        }
      }

      return ret;
    },
    checkIsSelectedColorCol(val)
    {
      let event = this.event;
      let ret = '';

      let bet = 'lucky6';

      for(let line of this.sectors)
      {
        for(let lbet of line.bets)
        {

          if(lbet.class === val)
          {
            bet = bet + '_' + lbet.value;
          }
        }
      }

      for(let sel of this.selected)
      {
        if(sel.event === event)
        {
          if(sel.bet === bet)
          {
            ret += ' selected';
          }
        }
      }

      return ret;
    },
    randomPick(cnt)
    {
      this.cur_split_selected = [];

      for(let i = 1; i <= cnt; i++)
      {
        this.cur_split_selected.push({
          bet: Math.floor(Math.random() * 80+1).toString(),
          event: this.event
        });
      }
    },
    setMode(mode, cnt)
    {
      if(mode !== 'normal' && cnt !== 0)
      {
        this.cur_split_selected = [];
      }

      if(mode === this.pickMode && cnt === this.pickCount)
      {
        this.pickMode = 'normal';
        this.pickCount = 0;
      }
      else
      {
        this.pickMode = mode;
        this.pickCount = cnt;
      }
    },
    clear_bet()
    {
      this.selected = [];
    },
    addBetEvent(event)
    {
      if (event.keyCode == 32)
      {
        event.preventDefault();
        this.addBet();
      }
    },
    chooseColumn(column)
    {
      let bet = 'lucky6';
      let bet_name = '';

      for(let line of this.sectors)
      {
        for(let lbet of line.bets)
        {

          if(lbet.class === column)
          {
            if(bet_name !== '')
            {
              bet_name = bet_name + ';';
            }
            bet_name = bet_name + lbet.value;
            bet = bet + '_' + lbet.value;
          }
        }
      }

      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.selected.splice(this.selected.indexOf(found), 1);
      }
      else
      {
        this.selected.push({
          bet: bet,
          event: this.event,
          public_event: this.public_event,
          bet_value: this.bet_value,
          multiplier: 1,
          bet_name: bet_name,
          ntt: 'n'
        });
      }

      this.$emit('getBet', this.selected);

      this.cur_split_selected = [];
    },
    firstColor(color)
    {
      if(this.cur_mode != 'fcolor' && this.cur_mode !== '')
      {
        this.cur_split_selected = [];
      }

      this.cur_mode = 'fcolor';

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === color)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 4)
        {
          this.cur_split_selected.push({bet: color, event: this.event});
        }
      }

      this.$emit('getBet', this.selected);

      //this.cur_split_selected = [];
    },
    lastColor(color)
    {
      if(this.cur_mode != 'lcolor' && this.cur_mode !== '')
      {
        this.cur_split_selected = [];
      }

      this.cur_mode = 'lcolor';

      let found = '';

      for (let sel of this.cur_split_selected)
      {
        if (sel.bet === color)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.cur_split_selected.splice(this.cur_split_selected.indexOf(found), 1);
      }
      else
      {
        if(this.cur_split_selected.length < 4)
        {
          this.cur_split_selected.push({bet: color, event: this.event});
        }
      }

      this.$emit('getBet', this.selected);

      //this.cur_split_selected = [];
    },
    addAbet(bet)
    {

      let found = '';

      for (let sel of this.selected)
      {
        if (sel.bet === bet.bet)
        {
          found = sel;
        }
      }

      if (found !== '')
      {
        this.selected.splice(this.selected.indexOf(found), 1);
      }
      else
      {
        this.selected.push({
          bet: bet.bet,
          event: this.event,
          public_event: this.public_event,
          bet_value: this.bet_value,
          multiplier: 1,
          bet_name: bet.altName,
          ntt: 'n'
        });
      }

      this.$emit('getBet', this.selected);
    },
    checkDisAdd()
    {
      let cssl = this.cur_split_selected.length;
      let cm = this.cur_mode;
      return (cssl < 6 && cm === 'main') || (cm != 'main' && (cssl === 0 || cssl === 3));
    }
  },
  mounted() {
    window.addEventListener('keyup', this.addBetEvent);
  },
  computed: {
    cur_nums() {
      let cur_nums = '';
      let cur_nums_dop = '';

      let i = 0;

      for(let cn of this.cur_split_selected)
      {
        i++;

        if(i > 0 && i < 7)
        {
          if(cur_nums === '')
          {
            cur_nums = cn.bet;
          }
          else
          {
            cur_nums = cur_nums+';'+cn.bet;
          }
        }

        if(i >= 7)
        {
          if(cur_nums_dop === '')
          {
            cur_nums_dop = cn.bet;
          }
          else
          {
            cur_nums_dop = cur_nums_dop+';'+cn.bet;
          }
        }
      }

      if(cur_nums_dop !== '')
      {
        cur_nums_dop = '<span style="background-color: #ff212e">'+cur_nums_dop+'</span>';
        cur_nums = cur_nums+';'+cur_nums_dop;
      }

      return cur_nums;
    }
  }
}
</script>

<style scoped>

  #workspace
  {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cornflowerblue;
  }
  #game_bets
  {
    height: 98%;
    width: 100%;
    display: grid;
    grid-template-rows: 9% 9% 9% 9% 9% 9% 9% 4% 7% 9% 7% 9%;
  }
  .verticals_cont
  {
    display: flex;
    flex-direction: column;
  }
  .bet_area
  {
    height: 98%;
    width: 95%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .bet_cell
  {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DDDDDD;
    margin: 2px;
    cursor:pointer;
    font-size: 100%;
    font-weight: bold;
    background-color: darkgreen;
  }
  .selected
  {
    box-shadow: inset 0px 0px 0px 5px #53ea93;
    box-sizing: border-box;
    background-color: darkorchid;
    color: white;
  }
  .selectedAdd
  {
    background-color: darkgray !important;
  }
  .selected_shatters
  {
    background-color: darkkhaki;
    color: white;
  }
  .bet_line
  {
    width: 100%;
    display: grid;
    grid-template-columns: 12% 12% 12% 12% 12% 12% 12% 12%;
  }
  .bisque
  {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    margin: 5px;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .horizontals
  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%
  }
  .verticals
  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  .randoms
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .controls_cont
  {
    display: grid;
    grid-template-rows: 30% 70%;
  }
  .abets
  {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .add_cont
  {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .abets_half
  {
    height: 100%;
    display: grid;
    grid-template-rows: 16% 16% 16% 16% 16% 16%;
  }
  .control_buttons
  {
    display: flex;

  }
  .btn_ctrl
  {
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: aliceblue;
    color: black;
    height: 30%;
    width: 80px;
    cursor: pointer;
    margin-right: 1%;
  }
  .btn_abet
  {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    height: 93%;
    width: 98%;
    cursor: pointer;
    font-size: 1vw;
  }
  .mb div
  {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    background-color: darkgreen;
  }
  .mb
  {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    background-color: darkgray;
    cursor: pointer;
  }
  .hb
  {
    height: 30px;
    margin-left: 5px;
  }
  .vb
  {
    margin-top: 5px;
    width: 30px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }
  .vb div
  {
    margin-top: 2px;
  }
  .mbx
  {
    height: 30px;
    width: 30px;
    background-color: white;
    margin-left: 5px;
  }
  .cur_nums_cont
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cur_nums
  {
    border: 1px solid;
    background-color: white;
    height: 30%;
    width: 99%;
    justify-self: end;
    align-self: center;
    margin-right: 2%;
    font-size: 1vw;
  }
  .disAdd
  {
    background-color: darkgray;
  }
  .red
  {
    background-color: #ff212e;
  }
  .green
  {
    background-color: #1ec42b;
  }
  .blue
  {
    background-color: blue;
  }
  .violet
  {
    background-color: violet;
  }
  .brown
  {
    background-color: brown;
  }
  .yellow
  {
    background-color: #c4bf15;
  }
  .orange
  {
    background-color: orange;
  }
  .black
  {
    background-color: black;
  }
</style>