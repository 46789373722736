export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    }
  }
}