export default {
  "app": {
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассир"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "tables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столы"])}
  },
  "cashier": {
    "applybet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка"])},
    "betName": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["32"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36"])},
      "00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00"])},
      "1-18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18"])},
      "1-18 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Черный)"])},
      "1-18 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Красный)"])},
      "11 & 22 & 33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "11_22_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 & 22 & 33"])},
      "12 & 21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "12_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 & 21"])},
      "13 & 31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "13_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13 & 31"])},
      "19-36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36"])},
      "19-36 (Black)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Черный)"])},
      "19-36 (Red)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Красный)"])},
      "23 & 32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "23_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 & 32"])},
      "Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черный"])},
      "Column 1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колонка 1"])},
      "Column 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колонка 2"])},
      "Column 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колонка 3"])},
      "Corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угол"])},
      "Even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чет"])},
      "Line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия"])},
      "Odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нечет"])},
      "PLAYER_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_0"])},
      "PLAYER_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_00"])},
      "PLAYER_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_1"])},
      "PLAYER_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_10"])},
      "PLAYER_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_11"])},
      "PLAYER_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_12"])},
      "PLAYER_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_13"])},
      "PLAYER_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_14"])},
      "PLAYER_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_15"])},
      "PLAYER_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_16"])},
      "PLAYER_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_17"])},
      "PLAYER_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_18"])},
      "PLAYER_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_19"])},
      "PLAYER_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_2"])},
      "PLAYER_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_20"])},
      "PLAYER_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_21"])},
      "PLAYER_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_22"])},
      "PLAYER_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_23"])},
      "PLAYER_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_24"])},
      "PLAYER_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_25"])},
      "PLAYER_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_26"])},
      "PLAYER_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_27"])},
      "PLAYER_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_28"])},
      "PLAYER_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_29"])},
      "PLAYER_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_3"])},
      "PLAYER_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_30"])},
      "PLAYER_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_31"])},
      "PLAYER_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_32"])},
      "PLAYER_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_33"])},
      "PLAYER_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_34"])},
      "PLAYER_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_35"])},
      "PLAYER_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_36"])},
      "PLAYER_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_4"])},
      "PLAYER_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_5"])},
      "PLAYER_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_6"])},
      "PLAYER_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_7"])},
      "PLAYER_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_8"])},
      "PLAYER_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИГРОК_9"])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Красный"])},
      "Six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Линия(6)"])},
      "Split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сплит"])},
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["черный"])},
      "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["колонка1"])},
      "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["колонка2"])},
      "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["колонка3"])},
      "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["угол"])},
      "doz1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дюж1"])},
      "doz2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дюж2"])},
      "doz3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дюж3"])},
      "even": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чет"])},
      "evens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НИЧЬЯ"])},
      "half1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["половина1"])},
      "half1_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Черный)"])},
      "half1_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-18 (Красный)"])},
      "half2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["половина2"])},
      "half2_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Черный)"])},
      "half2_red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19-36 (Красный)"])},
      "heads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПЕРВАЯ ПОЛОВИНА"])},
      "keno1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено1"])},
      "keno10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено10"])},
      "keno2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено2"])},
      "keno3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено3"])},
      "keno4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено4"])},
      "keno5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено5"])},
      "keno6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено6"])},
      "keno7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено7"])},
      "keno8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено8"])},
      "keno9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кено9"])},
      "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["линия"])},
      "odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нечет"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["красный"])},
      "sector0_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_a"])},
      "sector0_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_b"])},
      "sector0_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_c"])},
      "sector0_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_d"])},
      "sector0_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_e"])},
      "sector0_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_f"])},
      "sector_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_0"])},
      "sector_00": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_00"])},
      "sector_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_1"])},
      "sector_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_10"])},
      "sector_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_11"])},
      "sector_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_12"])},
      "sector_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_13"])},
      "sector_14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_14"])},
      "sector_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_15"])},
      "sector_16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_16"])},
      "sector_17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_17"])},
      "sector_18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_18"])},
      "sector_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_19"])},
      "sector_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_2"])},
      "sector_20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_20"])},
      "sector_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_21"])},
      "sector_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_22"])},
      "sector_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_23"])},
      "sector_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_24"])},
      "sector_25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_25"])},
      "sector_26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_26"])},
      "sector_27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_27"])},
      "sector_28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_28"])},
      "sector_29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_29"])},
      "sector_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_3"])},
      "sector_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_30"])},
      "sector_31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_31"])},
      "sector_32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_32"])},
      "sector_33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_33"])},
      "sector_34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_34"])},
      "sector_35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_35"])},
      "sector_36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_36"])},
      "sector_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_4"])},
      "sector_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_5"])},
      "sector_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_6"])},
      "sector_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_7"])},
      "sector_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_8"])},
      "sector_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НОМЕР_9"])},
      "sector_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_a"])},
      "sector_b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_b"])},
      "sector_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_c"])},
      "sector_d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_d"])},
      "sector_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_e"])},
      "sector_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сектор_f"])},
      "six-line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["линия(6)"])},
      "split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сплит"])},
      "tails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВТОРАЯ ПОЛОВИНА"])}
    },
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачена"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принята"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проигрыш"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает подтверждения"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выигрыш"])},
    "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ставки"])},
    "betsOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СТАВОК БОЛЬШЕ НЕТ"])},
    "betstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус ставки"])},
    "betsvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма ставок"])},
    "betvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма ставки"])},
    "button_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "cancelreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена чека"])},
    "clearall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
    "close_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "error": {
      "Bet sum cannot includes more than 2 decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма ставки не может содержать больше 2х десятичных знаков"])},
      "Cannot cancel receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отменить чек: "])},
      "Cannot make receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать чек: "])},
      "Cannot payout receipt:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось выплатить чек: "])},
      "Cannot refill box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось пополнить бокс: "])},
      "Cannot withdraw box:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обналичить бокс: "])},
      "Game untied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра не привязана"])},
      "Insufficient funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно средств"])},
      "Insufficient privileges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно прав"])},
      "Invalid secure code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код безопасности"])},
      "One or more events were already started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одно или несколько событий уже запущено"])},
      "Receipt already paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чек уже выплачен"])},
      "Receipt rejects limit reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнут лимит отмен чеков"])},
      "Value must be above 0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение должно быть больше 0"])}
    },
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
    "game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс:"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мин:"])},
    "notpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выплачено"])},
    "notplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сыграно"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата"])},
    "payoutprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата (печать)"])},
    "printcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Печать копии"])},
    "race": {
      "even_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЧЕТ"])},
      "high_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БОЛЬШЕ"])},
      "lower_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МЕНЬШЕ"])},
      "no_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "no_place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕ В ДВОЙКЕ"])},
      "no_show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕ В ТРОЙКЕ"])},
      "no_win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕ 1-Е МЕСТО"])},
      "odd_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НЕЧЕТ"])},
      "place_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ДВОЙКЕ"])},
      "prizes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПРИЗЕРЫ"])},
      "show_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ТРОЙКЕ"])},
      "sw_exacta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЭКЗАКТА"])},
      "sw_quinella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КИНЕЛА"])},
      "sw_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОП"])},
      "sw_trifecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТРИФЕКТА"])},
      "sw_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Е МЕСТО"])},
      "win_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-Е МЕСТО"])},
      "yes_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
    },
    "receipt_num": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чек #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проигрыш"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выплачен"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сыгран"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачен"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает подтверждения"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играет"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменен"])},
    "receiptdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата принятия"])},
    "receiptpaiddate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выплаты"])},
    "repeat_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтор чека"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раунд #"])},
    "securecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код безопасности:"])},
    "splitSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разделить сумму"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "totalpayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого к выплате:"])},
    "vgtOptions": {
      "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для таблицы"])},
      "nextLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая"])},
      "ofLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])},
      "pageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница"])},
      "prevLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предыдущая"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на страницу"])}
    }
  },
  "gametemplate": {
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не активна!!!"])}
  },
  "login": {
    "chooseLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык:"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])}
  },
  "statistics": {
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ставка"])},
    "betStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус ставки"])},
    "betValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма ставки"])},
    "bet_status_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принята"])},
    "bet_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проиграла"])},
    "bet_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачена"])},
    "bet_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает подтверждения"])},
    "bet_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменена"])},
    "bet_status_win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выиграла"])},
    "cancelReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена чека"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "detail_table_#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "detail_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесено"])},
    "detail_table_Game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра"])},
    "detail_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата"])},
    "detail_table_Payout date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата выплаты"])},
    "detail_table_Payout login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин выплатившего"])},
    "detail_table_Receipt date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата принятия"])},
    "detail_table_Receipt login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин принявшего"])},
    "detail_table_Reject date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отмены"])},
    "detail_table_Reject login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин отменившего"])},
    "detail_table_Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детализированный"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
    "export_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
    "find": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overview"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата"])},
    "payoutPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата (печать)"])},
    "payout_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплатить"])},
    "printCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Печать копии"])},
    "printReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Печать отчета"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чек #"])},
    "receipt_status_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проигрыш"])},
    "receipt_status_not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выплачен"])},
    "receipt_status_not_played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сыгран"])},
    "receipt_status_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачен"])},
    "receipt_status_pending_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает подтверждения"])},
    "receipt_status_playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играет"])},
    "receipt_status_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменен"])},
    "secureCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код безопасности:"])},
    "setTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить время "])},
    "stat_table_Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "stat_table_Entered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесено"])},
    "stat_table_Payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачено"])},
    "stat_table_Pending payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемая выплата"])},
    "stat_table_Receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеков принято"])},
    "stat_table_Receipts rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеков отменено"])},
    "stat_table_Total deposited (cashier)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесено (кассир)"])},
    "stat_table_Total deposited (wallet)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внесено (купюроприемник)"])},
    "stat_table_Total paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплачено"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика по играм"])},
    "statistics_tbl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика по столам"])},
    "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого к выплате:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать"])}
  },
  "tables": {
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кнопки"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "Deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "Lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать"])},
    "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
    "Unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать"])},
    "Unlock stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать (stat)"])},
    "Withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять"])},
    "history": {
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "History of refill/withdraw box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История пополнения/снятия бокса"])},
      "No data found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные не найдены"])},
      "Shift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смена"])},
      "Show all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])},
      "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стол"])},
      "Total refill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего пополнение"])},
      "Total withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего снятие"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])},
      "shown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["показано"])},
      "ttype": {
        "refill_acceptor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение [Купюроприемник]"])},
        "refill_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение [Кассир]"])},
        "withdraw_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снятие [Кассир]"])}
      }
    },
    "state": {
      "blocked_cashier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_кассир"])},
      "blocked_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_ошибка"])},
      "blocked_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_выплата"])},
      "blocked_pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_пин("])},
      "blocked_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_stat"])},
      "blocked_stat_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заблокирован_stat_pending"])},
      "in_game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в_игре"])},
      "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["готов"])}
    }
  }
}